import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { LoaderSize, StackingState } from '../../../utils/constants';
import { ContinuousProgressBar } from '../../../components/styled/ProgressBar';
import { RowFlex } from '../../../components/styled/Flex';
import BarLoader from '../../../components/styled/BarLoader';
import { Theme } from '../../../Theme';
import { StackingData, StackingStatus } from '../../../utils/data.type';

interface ColorProps {
  red?: boolean;
  yellow?: boolean;
}

const StatusContainer = styled.div<ColorProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: props.red
    ? 'rgba(211, 60, 60, 0.15)'
    : props.yellow
    ? 'rgba(242, 169, 0, 0.15)'
    : 'rgba(81, 214, 166, 0.15)',
  borderRadius: 30,
  paddingLeft: 9,
  paddingRight: 12,
  paddingTop: 5,
  paddingBottom: 5,
}));

const RowContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ProgressBarContainer = styled.div((props) => ({
  marginLeft: 16,
}));

const Container = styled.div((props) => ({
  display: 'flex',
  alignItems: 'flex-start',
  height: 50,
  width: '100%',
  paddingBottom: 5,
}));

const StatusText = styled.h1((props) => ({
  ...props.theme.body_xs,
  fontWeight: 500,
  color: props.theme.colors.white,
}));

const StatusDetailedText = styled.h1((props) => ({
  ...props.theme.body_xs,
  fontWeight: 500,
  fontSize: 16,
  marginLeft: 4,
  color: props.theme.colors.lightGrey,
}));

const DaysLeftText = styled.h1((props) => ({
  ...props.theme.body_xs,
  fontWeight: 500,
  fontSize: 16,
  marginLeft: 4,
  color: props.theme.colors.white,
}));

const Dot = styled.div<ColorProps>((props) => ({
  width: 7,
  height: 7,
  borderRadius: 30,
  marginRight: 7,
  background: props.red
    ? props.theme.colors.red
    : props.yellow
    ? props.theme.colors.yellow
    : props.theme.colors.green,
}));

interface Props {
  stackingStatus: StackingStatus;
  progressPercent: number;
  currCycleEndsIn: string;
  poolCloseIn: string;
  stackingData: StackingData;
}
export const StackingStatusView = ({
  stackingStatus,
  progressPercent,
  currCycleEndsIn,
  poolCloseIn,
  stackingData,
}: Props) => {
  const [poolAvailable, setPoolAvailable] = useState(false);

  useEffect(() => {
    if (stackingData) {
      if (stackingData?.poolInfo?.pools?.length! > 0) {
        setPoolAvailable(true);
      }
    }
  }, [stackingData]);
  if (!stackingData)
    return (
      <Container>
        <BarLoader loaderSize={LoaderSize.SMALL} color={Theme.colors.background} margin={'0'} />
      </Container>
    );
  if (
    stackingStatus.stackingState === StackingState.NotStacking ||
    stackingStatus.stackingState === StackingState.Revoked
  ) {
    return (
      <RowContainer>
        <StatusContainer
          red={!poolAvailable || !stackingData?.poolInfo?.open}
          yellow={currCycleEndsIn === '1'}
        >
          <Dot
            red={!poolAvailable || !stackingData?.poolInfo?.open}
            yellow={currCycleEndsIn === '1'}
          />
          <StatusText>
            {poolAvailable && stackingData?.poolInfo?.open ? 'Open' : 'Closed'}
          </StatusText>
        </StatusContainer>
        <StatusDetailedText>
          Registration for upcoming cycle will{' '}
          {poolAvailable && stackingData?.poolInfo?.open ? 'close ' : 'open '}
          in
        </StatusDetailedText>
        <DaysLeftText>
          {poolAvailable && stackingData?.poolInfo?.open ? poolCloseIn : currCycleEndsIn}
        </DaysLeftText>
      </RowContainer>
    );
  } else {
    return (
      <RowContainer>
        <StatusContainer yellow={stackingStatus.stackingState === StackingState.Pending}>
          <Dot yellow={stackingStatus.stackingState === StackingState.Pending} />
          <StatusText>
            {stackingStatus.stackingState === StackingState.Pending
              ? 'Pending delegation'
              : stackingStatus.stackingState === StackingState.Stacking
              ? 'In progress'
              : 'Delegation confirmed'}
          </StatusText>
        </StatusContainer>
        {stackingStatus.stackingState === StackingState.Pending ||
        stackingStatus.stackingState === StackingState.Delegated ? (
          <>
            <StatusDetailedText>Estimated start of the next cycle</StatusDetailedText>
            <DaysLeftText>{currCycleEndsIn}</DaysLeftText>
          </>
        ) : (
          <RowFlex>
            <ProgressBarContainer>
              <ContinuousProgressBar width="203px" value={progressPercent} />
            </ProgressBarContainer>
          </RowFlex>
        )}
      </RowContainer>
    );
  }
};
