import { Dispatch, useEffect, useState } from 'react';
import { StackingDashboardContainer } from '../../../components/styled/Container';
import { ColumnFlex, RowFlex } from '../../../components/styled/Flex';
import {
  StackingTileText,
  StackingHeadingText,
  StackingBodyText,
  StackingTileValueText,
  MediumBodyText,
  BoldHeadingText,
  StackingProgressText,
} from '../../../components/styled/Text';
import { StackingState } from '../../../utils/constants';
import { Action, Rates, StackingData, StackingStatus } from '../../../utils/data.type';
import StackingInfoTile from '../StackingInfoTile';
import { ContinuousProgressBar } from '../../../components/styled/ProgressBar';
import styled from 'styled-components';
import { useCycleData, useGeneratedCycleBtcReward } from '../../../hooks/useCycleData';
import { getStacksRates } from '../../../utils/wallet.utils';
import BigNumber from 'bignumber.js';

const ProgressBarContainer = styled.div({
  marginTop: 12,
});

const RowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

interface ColorProps {
  delegated?: boolean;
}

const Dot = styled.div<ColorProps>((props) => ({
  width: 7,
  height: 7,
  borderRadius: 30,
  marginRight: 7,
  background: props.delegated ? props.theme.colors.yellow : props.theme.colors.green,
}));

const Container = styled.div<ColorProps>((props) => ({
  marginTop: 22,
  width: 600,
  display: 'flex',
  flexDirection: 'column',
  border: props.delegated
    ? '1px solid rgba(242, 169, 0, 0.3)'
    : '1px solid rgba(81, 214, 166, 0.3)',
  background: props.delegated ? 'rgba(242, 169, 0, 0.1)' : 'rgba(81, 214, 166, 0.12)',
  borderRadius: 12,
  marginRight: 24,
  padding: 16,
}));

interface Props {
  stackingStatus: StackingStatus;
  dispatchStackingStatus: Dispatch<Action>;
  isDelegated: boolean;
  stackingData: StackingData;
  address: string;
  onStackMore: () => void;
}

export const StackingProgress = ({
  stackingStatus,
  dispatchStackingStatus,
  isDelegated,
  stackingData,
  address,
  onStackMore,
}: Props) => {
  const pox = stackingData?.poolInfo?.pox;
  const poxContractAddress = pox?.contract_id?.split('.')[0]!;
  const poxContractName = pox?.contract_id?.split('.')[1]!;
  const {
    cyclesValue,
    nextPayout,
    currCycleEndsIn,
    rewardAddress,
    delegateToAddress,
    lockedAmount,
    delegatedAmount,
    progressPercent,
  } = useCycleData(stackingStatus, address, stackingData);
  const { generatedRewardInBtc, generatedRewardInCurrency } = useGeneratedCycleBtcReward(
    stackingData,
    lockedAmount,
  );
  const [rates, setRates] = useState<Rates | null>(null);

  const pool = stackingData?.poolInfo?.pools?.[0];
  const poolYield = pool?.estimated_yield ?? 0;
  const poolRewardAddress = pool?.reward_address ?? '';
  const isPoolClosed = !pool || !stackingData?.poolInfo?.open;

  useEffect(() => {
    async function fetchData() {
      try {
        const rates = await getStacksRates();
        setRates(rates);
      } catch (error) {}
    }
    fetchData();
  }, []);

  const stxAmountFiatRate = Boolean(rates)
    ? new BigNumber(lockedAmount)
        .multipliedBy(rates.stxBtcRate)
        .multipliedBy(rates.btcFiatRate)
        .toFixed(2)
    : '';

  const renderCurrentRewardGenerated = () => {
    return (
      <>
        <StackingTileText marginTop={30}>CURRENT CYCLE GENERATED REWARDS</StackingTileText>
        <StackingHeadingText marginTop={2}>{`${generatedRewardInBtc} BTC`}</StackingHeadingText>
        <StackingBodyText marginTop={1} color={`shadedWhite`}>
          {`~ $ ${generatedRewardInCurrency} USD`}
        </StackingBodyText>
      </>
    );
  };

  const renderRewardDistPeriod = () => {
    return (
      <ColumnFlex>
        <StackingTileText marginTop={30}>NEXT REWARD DISTRIBUTION</StackingTileText>
        <StackingTileValueText marginTop={2}>{nextPayout}</StackingTileValueText>
      </ColumnFlex>
    );
  };

  const renderProgressBar = () => {
    return (
      <ColumnFlex>
        <StackingTileText>ACTIVE STACKING PROGRESS</StackingTileText>
        <ProgressBarContainer>
          <ContinuousProgressBar width="553px" value={progressPercent} />
        </ProgressBarContainer>
        <RowFlex>
          <StackingProgressText marginTop={15}>{progressPercent}% completed</StackingProgressText>
        </RowFlex>
      </ColumnFlex>
    );
  };

  const renderDelegationInfoSection = (
    <Container delegated={stackingStatus.stackingState === StackingState.Pending}>
      <RowContainer>
        <Dot delegated={stackingStatus.stackingState === StackingState.Pending} />
        <BoldHeadingText>
          {' '}
          {stackingStatus.stackingState === StackingState.Pending
            ? 'Pending delegation'
            : 'Delegation confirmed'}
        </BoldHeadingText>
      </RowContainer>
      <MediumBodyText fontSize={12} marginTop={2} color={'shadedWhite'}>
        {stackingStatus.stackingState === StackingState.Pending
          ? ' Your registration to the pool is being processed, this can take up to a few days.'
          : ' Your STX will be automatically locked at the beginning of the next cycle.'}
      </MediumBodyText>
    </Container>
  );

  function renderStackingSection() {
    return (
      <RowFlex justifyContent="center">
        <ColumnFlex>
          <StackingDashboardContainer
            marginTop={128}
            marginRight={24}
            paddingBottom={24}
            paddingLeft={28}
            width={600}
          >
            {renderCurrentRewardGenerated()}
            <div
              style={{
                width: 553,
                height: 0,
                border: '1px solid #272A44',
                marginTop: 24,
              }}
            ></div>
            <RowFlex justifyContent="flex-start">{renderRewardDistPeriod()}</RowFlex>
          </StackingDashboardContainer>
          <StackingDashboardContainer marginTop={24} marginRight={24} padding={24}>
            {renderProgressBar()}
          </StackingDashboardContainer>
        </ColumnFlex>
        <StackingInfoTile
          stackingState={stackingStatus?.stackingState}
          poolYield={poolYield}
          cyclesValue={cyclesValue}
          currCycleEndsIn={currCycleEndsIn}
          stxAmountFiatRate={stxAmountFiatRate}
          rewardAddress={rewardAddress}
          poolAddress={poolRewardAddress}
          delegatedToAddress={delegateToAddress}
          dispatchStackingStatus={dispatchStackingStatus}
          lockedAmount={lockedAmount}
          delegatedAmount={delegatedAmount}
          poxContractAddress={poxContractAddress}
          poxContractName={poxContractName}
          isDelegated={isDelegated}
          onStackMore={onStackMore}
          isPoolClosed={isPoolClosed}
          registeredForNextCycleState={
            stackingStatus.delegatedStxData.hasPendingDelegate ? 'pending' : undefined
          }
        />
      </RowFlex>
    );
  }

  function renderStackingDelegatedSection() {
    return (
      <RowFlex justifyContent="flex-start">
        <ColumnFlex>
          <StackingDashboardContainer marginTop={128} marginRight={24} padding={24} width={600}>
            <StackingTileText>EST. START OF THE NEXT CYCLE</StackingTileText>
            <StackingHeadingText marginTop={2}>{currCycleEndsIn}</StackingHeadingText>
          </StackingDashboardContainer>
          {isDelegated && renderDelegationInfoSection}
        </ColumnFlex>
        <StackingInfoTile
          stackingState={stackingStatus?.stackingState}
          stxAmountFiatRate={stxAmountFiatRate}
          poolYield={poolYield}
          cyclesValue={cyclesValue}
          currCycleEndsIn={currCycleEndsIn}
          rewardAddress={rewardAddress}
          poolAddress={poolRewardAddress}
          dispatchStackingStatus={dispatchStackingStatus}
          lockedAmount={lockedAmount}
          delegatedAmount={delegatedAmount}
          isDelegated={isDelegated}
          poxContractAddress={poxContractAddress}
          poxContractName={poxContractName}
          delegatedToAddress={delegateToAddress}
          onStackMore={onStackMore}
          isPoolClosed={isPoolClosed}
          registeredForNextCycleState={
            stackingStatus.delegatedStxData.hasPendingDelegate ? 'pending' : undefined
          }
        />
      </RowFlex>
    );
  }

  return stackingStatus?.stackingState === StackingState.Stacking
    ? renderStackingSection()
    : renderStackingDelegatedSection();
};
