import { Container } from '../../../components/styled/Container';
import { ColumnFlex, RowFlex } from '../../../components/styled/Flex';
import {
  BodyText,
  CurrencyText,
  PopUpText,
  StackingBodyText,
  StackingTileSubHeadingText,
  StackingTileText,
  StackingTileValueText,
} from '../../../components/styled/Text';
import StacksToken from '../../../assets/images/TockenTicker.svg';
import StacksLocked from '../../../assets/images/LockSimple.svg';
import X from '../../../assets/images/X.svg';
import Check from '../../../assets/images/Green-Arrow.svg';
import Copy from '../../../assets/images/Copy.svg';
import More from '../../../assets/images/more.svg';
import Prohibit from '../../../assets/images/prohibit.svg';
import { Button, PrimaryButton, TransparentSquareButton } from '../../../components/styled/Button';
import { Dispatch, useContext, useState, useEffect } from 'react';
import { PopUp } from '../../../components/styled/Popup';
import { generateUnsignedRevokeTransaction } from '../../../utils/transaction';
import { LoginContext } from '../../../Context/LoginState';
import { POOL_ADMIN_ADDRESS, StackingState } from '../../../utils/constants';
import Seperator from '../../../components/Seperator';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { Action, DelegatedStxData } from '../../../utils/data.type';

const SeperatorContainer = styled.div({
  marginTop: 32,
  marginBottom: 24,
});

const TextContainer = styled.div({
  display: 'inline',
});

const OuterContainer = styled.div({
  width: '100%',
  height: '100%',
  right: 0,
  left: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'rgba(16, 19, 32, 0.7)',
  margin: 'auto',
  position: 'fixed',
});

const ImageButton = styled.button({
  background: 'transparent',
});

const DaysLeftText = styled.span((props) => ({
  ...props.theme.body_m,
  fontWeight: 900,
  color: 'white',
}));

const AddressText = styled.span((props) => ({
  ...props.theme.body_m,
  color: 'white',
  overflowWrap: 'break-word',
  marginTop: 2,
  width: 300,
}));

const StackingInfoTileContainer = styled(ColumnFlex)({
  marginTop: 128,
});

const InfoTileContainer = styled.div<{ small?: boolean }>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 384,
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: props.small ? 24 : 0,
  paddingBottom: props.small ? 16 : 37,
  marginBottom: props.small ? 24 : 0,
  background: 'linear-gradient(42.24deg, #1d2032 0%, rgba(29, 32, 50, 0) 100%)',
  border: '1px solid #272a44',
  borderRadius: 8,
}));

const Img = styled.img({
  marginRight: 8,
});

const Pill = styled.div<{ small?: boolean }>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#F2A90026',
  padding: '5px 8px',
  borderRadius: 30,
}));
const Dot = styled.div<{ small?: boolean }>((props) => ({
  width: 7,
  height: 7,
  borderRadius: 7,
  backgroundColor: props.theme.colors.yellow,
  marginRight: 8,
}));

interface StackingInfoTileProps {
  stackingState?: string;
  lockedAmount: DelegatedStxData['lockedAmount'];
  delegatedAmount: DelegatedStxData['delegatedAmount'];
  stxAmountFiatRate?: string;
  poolYield: number;
  cyclesValue: number;
  rewardAddress: string;
  poxContractAddress?: string;
  poxContractName?: string;
  isDelegated?: boolean;
  currCycleEndsIn?: string;
  poolAddress?: string;
  delegatedToAddress?: string;
  isPoolClosed: boolean;
  hideActions?: boolean;
  registeredForNextCycleState?: 'in-progress' | 'pending';
  dispatchStackingStatus?: Dispatch<Action>;
  onStackMore?: () => void;
}

export const StackingInfoTile: React.FC<StackingInfoTileProps> = ({
  stackingState,
  hideActions = false,
  lockedAmount,
  delegatedAmount,
  stxAmountFiatRate,
  poolYield,
  cyclesValue,
  rewardAddress,
  poolAddress,
  delegatedToAddress,
  currCycleEndsIn,
  isDelegated,
  poxContractAddress,
  poxContractName,
  isPoolClosed = false,
  registeredForNextCycleState,
  dispatchStackingStatus,
  onStackMore,
}) => {
  const { userData } = useContext(LoginContext);
  const address = userData?.profile.stxAddress.mainnet;
  const [openCancelPopUp, setOpenCancelPopUp] = useState(false);
  const [isRewardAddressCopied, setIsRewardAddressCopied] = useState(false);
  const [isDelegatedToAddressCopied, setIsDelegatedToAddressCopied] = useState(false);
  const [isPoolAddressCopied, setIsPoolAddressCopied] = useState(false);

  useEffect(() => {
    if (isRewardAddressCopied) {
      setTimeout(() => {
        setIsRewardAddressCopied(false);
      }, 5000);
    }
  }, [isRewardAddressCopied]);

  useEffect(() => {
    if (isPoolAddressCopied) {
      setTimeout(() => {
        setIsPoolAddressCopied(false);
      }, 5000);
    }
  }, [isPoolAddressCopied]);

  useEffect(() => {
    if (isDelegatedToAddressCopied) {
      setTimeout(() => {
        setIsDelegatedToAddressCopied(false);
      }, 5000);
    }
  }, [isDelegatedToAddressCopied]);

  const isDifferentPoolStacking = delegatedToAddress !== POOL_ADMIN_ADDRESS;

  function renderStxLocked() {
    if (
      isDelegated ||
      stackingState === StackingState.Stacking ||
      delegatedToAddress !== POOL_ADMIN_ADDRESS
    ) {
      return (
        <Container flex={1}>
          <StackingTileText marginTop={3}>STX LOCKED</StackingTileText>
          <RowFlex justifyContent="flex-start">
            <NumberFormat
              value={lockedAmount.toString()}
              displayType="text"
              thousandSeparator
              suffix=" STX"
              renderText={(value: string) => (
                <StackingTileValueText marginTop={2}>{value}</StackingTileValueText>
              )}
            />
            <img
              src={StacksLocked}
              alt="StacksLocked"
              height={18}
              width={18}
              style={{ position: 'relative', marginTop: 10, marginLeft: 8 }}
            />
          </RowFlex>
          <NumberFormat
            value={stxAmountFiatRate}
            displayType="text"
            thousandSeparator
            prefix="~ $ "
            suffix=" USD"
            renderText={(value: string) => <CurrencyText marginTop={2}>{value ?? 0}</CurrencyText>}
          />
        </Container>
      );
    }
  }

  function renderPoolYield() {
    return (
      <Container flex={1} marginLeft={16}>
        <StackingTileText>EST. APY</StackingTileText>
        <StackingTileValueText marginTop={2}>
          {isDifferentPoolStacking ? '-' : `${poolYield ?? '~10'} %`}
        </StackingTileValueText>
      </Container>
    );
  }

  function renderCycleNumber() {
    return (
      <Container>
        <StackingTileText marginTop={3}>CYCLES COMPLETED</StackingTileText>
        <StackingTileValueText marginTop={2}>{cyclesValue}</StackingTileValueText>
      </Container>
    );
  }

  function renderDelegatedTo() {
    return (
      <>
        <StackingTileText marginTop={3}>DELEGATED TO</StackingTileText>
        <RowFlex justifyContent="space-between">
          <StackingTileSubHeadingText marginTop={2} width={300}>
            {delegatedToAddress}
          </StackingTileSubHeadingText>
          <ImageButton
            onClick={() => {
              navigator.clipboard.writeText(delegatedToAddress);
              setIsDelegatedToAddressCopied(true);
            }}
          >
            <img src={isDelegatedToAddressCopied ? Check : Copy} alt="copy" />
          </ImageButton>
        </RowFlex>
      </>
    );
  }

  function renderPoolAddress() {
    return (
      <>
        <StackingTileText marginTop={3}>POOL REWARD ADDRESS</StackingTileText>
        <RowFlex justifyContent="space-between">
          <AddressText>{poolAddress}</AddressText>
          <ImageButton
            onClick={() => {
              navigator.clipboard.writeText(poolAddress);
              setIsPoolAddressCopied(true);
            }}
          >
            <img src={isPoolAddressCopied ? Check : Copy} alt="copy" />
          </ImageButton>
        </RowFlex>
      </>
    );
  }

  function renderRewardAddress() {
    return (
      <>
        <StackingTileText>REWARD ADDRESS</StackingTileText>
        <RowFlex justifyContent="space-between">
          <AddressText>{rewardAddress !== '' ? rewardAddress : '-'}</AddressText>
          {rewardAddress !== '' &&
            (stackingState === 'Pending' ||
              stackingState === 'Delegated' ||
              stackingState === 'Stacking') && (
              <ImageButton
                onClick={() => {
                  navigator.clipboard.writeText(rewardAddress);
                  setIsRewardAddressCopied(true);
                }}
              >
                <img src={isRewardAddressCopied ? Check : Copy} alt="copy" />
              </ImageButton>
            )}
        </RowFlex>
      </>
    );
  }

  function ifDelegatedStx() {
    return (
      <TextContainer>
        {!hideActions && (
          <RowFlex marginTop={24}>
            <TransparentSquareButton
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
              height={48}
              marginRight={isDifferentPoolStacking ? 0 : 12}
              onClick={openDialog}
            >
              <Img src={Prohibit} alt="stop" />
              Stop stacking
            </TransparentSquareButton>
            {!isDifferentPoolStacking && (
              <PrimaryButton
                display="flex"
                flex={1}
                alignItems="center"
                justifyContent="center"
                onClick={onStackMore}
                disabled={isPoolClosed}
              >
                <Img src={More} alt="plus" />
                Stack more
              </PrimaryButton>
            )}
          </RowFlex>
        )}
        {isPoolClosed && (
          <StackingTileSubHeadingText marginTop={24} color="grey" width={309}>
            The pool will open in approximately <DaysLeftText>{currCycleEndsIn}</DaysLeftText>
          </StackingTileSubHeadingText>
        )}
        {renderRevokeStackingPopUp()}
      </TextContainer>
    );
  }
  const closeDialog = () => {
    setOpenCancelPopUp(false);
  };

  const openDialog = () => {
    setOpenCancelPopUp(true);
  };

  const revokeStacking = async () => {
    await generateUnsignedRevokeTransaction(
      address,
      poxContractAddress,
      poxContractName,
      dispatchStackingStatus,
    );
    setOpenCancelPopUp(false);
  };

  function renderRevokeStackingPopUp() {
    return (
      <>
        {openCancelPopUp && <OuterContainer onClick={closeDialog} />}
        <PopUp state={openCancelPopUp}>
          <>
            <img
              src={X}
              style={{ top: '20px', left: '270px', position: 'relative' }}
              alt="Close"
              onClick={closeDialog}
            />
            <PopUpText paddingLeft={15} paddingRight={15}>
              Cancel stacking
            </PopUpText>
            <StackingBodyText
              size={14}
              width={295}
              marginTop={20}
              paddingLeft={15}
              paddingRight={15}
              marginBottom={20}
            >
              If you are already stacking this cycle, your STX will unlock the next cycle beginning
              in approximately <DaysLeftText>{currCycleEndsIn}</DaysLeftText>. You will receive
              rewards for the completed cycle.
            </StackingBodyText>
            <Button
              marginTop={100}
              marginBottom={45}
              marginLeft={15}
              marginRight={10}
              width={127.5}
              border={'1px solid #4C5187'}
              bg={'transparent'}
              onClick={closeDialog}
            >
              Back
            </Button>
            <PrimaryButton marginTop={100} marginRight={15} width={127.5} onClick={revokeStacking}>
              {' '}
              Stop Stacking
            </PrimaryButton>
          </>
        </PopUp>
      </>
    );
  }

  return (
    <StackingInfoTileContainer>
      {!isDifferentPoolStacking && delegatedAmount.gt(lockedAmount) && (
        <InfoTileContainer small>
          <RowFlex justifyContent="space-between" alignItems="center">
            <div>
              <StackingTileText>REGISTERED FOR NEXT CYCLE</StackingTileText>
              <NumberFormat
                value={delegatedAmount.toString()}
                displayType="text"
                thousandSeparator
                suffix=" STX"
                renderText={(value: string) => (
                  <StackingTileValueText marginTop={2}>{value}</StackingTileValueText>
                )}
              />
            </div>
            {registeredForNextCycleState && (
              <Pill>
                <Dot />
                <BodyText>
                  {registeredForNextCycleState === 'in-progress' ? 'In progress' : 'Pending'}
                </BodyText>
              </Pill>
            )}
          </RowFlex>
        </InfoTileContainer>
      )}
      <InfoTileContainer>
        <img
          src={StacksToken}
          alt="StacksToken"
          height={28}
          width={28}
          style={{ position: 'relative', marginTop: 27.2 }}
        />
        <RowFlex justifyContent="space-between">
          {renderStxLocked()}
          {renderPoolYield()}
        </RowFlex>
        <RowFlex marginTop={25} justifyContent="flex-start">
          {renderCycleNumber()}
        </RowFlex>
        {(isDelegated || stackingState === StackingState.Stacking) && ifDelegatedStx()}
        <SeperatorContainer>
          <Seperator />
        </SeperatorContainer>
        {renderRewardAddress()}
        {delegatedToAddress && renderDelegatedTo()}
        {poolAddress && renderPoolAddress()}
      </InfoTileContainer>
    </StackingInfoTileContainer>
  );
};
