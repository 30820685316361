import { FooterContainer } from './styled/Container';
import { FooterText } from './styled/Text';
import XLogo from '../assets/images/x-logo.png';
import { xverseTwitterUrl } from '../utils/url.constants';
import { RowFlex } from './styled/Flex';
import { ImageButton } from './styled/Button';

function redirectToTwitter() {
  window.open(xverseTwitterUrl, '_blank', 'noopener,noreferrer');
}

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <FooterText color={'white'}>© {currentYear} Secret Key Labs Limited</FooterText>
      <RowFlex justifyContent="flex-start">
        <ImageButton
          type="image"
          src={XLogo}
          onClick={() => redirectToTwitter()}
          width={20}
          height={20}
          alt="X Icon"
          style={{
            marginTop: 20,
            marginLeft: 0,
            position: 'relative',
          }}
        />
      </RowFlex>
    </FooterContainer>
  );
};
