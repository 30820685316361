export const POOL_ADMIN_ADDRESS = 'SPXVRSEH2BKSXAEJ00F1BY562P45D5ERPSKR4Q33';
export const POX_CONTRACT_ADDRESS = 'SP000000000000000000002Q6VF78';
export const POX_CONTRACT_NAME = 'pox-4';
export const DELEGATE_CONTRACT_ADDRESS = 'SP001SFSMC2ZY76PD4M68P3WGX154XCH7NE3TYMX';
export const DELEGATE_CONTRACT_NAME = 'pox4-pools';
export const DELEGATE_STX_FUNCTION_NAME = 'delegate-stx';
export const ALLOW_CONTRACT_CALL_FUNCTION_NAME = 'allow-contract-caller';
export const REVOKE_DELEGATION_FUNCTION_NAME = 'revoke-delegate-stx';
export const DELEGATION_STATE = 'delegation-state';
export const REDEMPTION_FEES = 0.5;

export enum StackingState {
  Loading = 'Loading',
  NotStacking = 'NotStacking',
  Pending = 'Pending',
  Delegated = 'Delegated',
  Stacking = 'Stacking',
  Completed = 'Completed',
  Revoked = 'Revoked',
  Error = 'Error',
}

export enum LoaderSize {
  SMALLEST,
  SMALL,
  MEDIUM,
  LARGE,
}

export enum TransactionType {
  TokenTransfer = 'token_transfer',
  ContractCall = 'contract_call',
  ContractDeploy = 'contract_deploy',
  Coinbase = 'coinbase',
  PoisonMicroblock = 'poison_microblock',
  Bitcoin = 'bitcoin',
  Unsupported = 'unsupported',
}

export enum TransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Invalid = 'invalid',
}

export enum TokenType {
  Fungible = 'fungible',
  NonFungible = 'non_fungible',
}

export const appDetails = {
  name: 'Xverse Pool',
  icon: 'https://uploads-ssl.webflow.com/624b08d53d7ac60ccfc11d8d/646358ec6bbfb7a8d93e115c_Icon-Extension-144x144.png',
};

export enum StartStackingSteps {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
}
