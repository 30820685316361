import styled from 'styled-components';

const CardContainer = styled.div((props) => ({
  boxSizing: 'border-box',
  background: 'linear-gradient(42.24deg, #1D2032 0%, rgba(29, 32, 50, 0) 100%)',
  border: '1px solid #303354',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 24,
  paddingBottom: 24,
}));

const TitleText = styled.h1((props) => ({
  ...props.theme.headline_s,
  marginTop: 18,
}));

const DescriptionText = styled.h1((props) => ({
  ...props.theme.body_l,
  marginTop: 7,
}));

interface Props {
  title: string;
  image: string;
  description: string;
}
export const PerkCard = ({ title, image, description }: Props) => {
  return (
    <CardContainer>
      <img src={image} alt="perk" width={32} height={32} />
      <TitleText>{title}</TitleText>
      <DescriptionText>{description}</DescriptionText>
    </CardContainer>
  );
};
