import axios from 'axios';
import BigNumber from 'bignumber.js';
import {
  CoreInfo,
  StackingPoolInfo,
  StxAddressData,
  StxAddressDataResponse,
  StxTransactionData,
  StxTransactionListData,
  StxTransactionResponse,
  RawTransactionResponse,
  CurrentRewardData,
  StackingCycleData,
  PoolBTCAddressInfo,
  StackerInfo,
  StxMempoolTransactionListData,
  StxMempoolResponse,
  StxMempoolTransactionData,
} from './data.type';
import { hiroMainnetBaseUrl, xverseApiBaseUrl } from './url.constants';
import { parseMempoolStxTransactionsData, parseStxTransactionData } from './wallet.utils';

const currency = 'USD';
const API_TIMEOUT_MILLI = 30000;

export async function fetchStxAddressData(stxAddress: string): Promise<StxAddressData> {
  const apiUrl = `${hiroMainnetBaseUrl}/v2/accounts/${stxAddress}?proof=0`;
  const balanceInfo = await axios.get<StxAddressDataResponse>(apiUrl, {
    method: 'GET',
  });
  if (balanceInfo) {
    const availableBalance = new BigNumber(balanceInfo.data.balance);
    const lockedBalance = new BigNumber(balanceInfo.data.locked);

    return {
      availableBalance: availableBalance,
      lockedStackBalance: lockedBalance,
    };
  }
}

export async function fetchStxToBtcRate(): Promise<BigNumber> {
  const response = await axios.get(`${xverseApiBaseUrl}/v1/prices/stx/btc`, {
    method: 'GET',
  });
  return new BigNumber(response?.data?.stxBtcRate);
}

export async function fetchBtcToCurrencyRate(): Promise<BigNumber> {
  const response = await axios.get(`${xverseApiBaseUrl}/v1/prices/btc/USD`, {
    method: 'GET',
  });
  return new BigNumber(response?.data?.btcFiatRate);
}

export async function fetchStackingPoolInfo(): Promise<StackingPoolInfo> {
  const response = await axios.get(`${xverseApiBaseUrl}/v1/pool/info?pool_version=5`, {
    method: 'GET',
  });
  return response.data;
}

export async function fetchCoreInfo(): Promise<CoreInfo> {
  const response = await axios.get(`${xverseApiBaseUrl}/v1/core/info`, {
    method: 'GET',
  });

  return response.data;
}

export async function fetchPoolBtcAddressInfo(): Promise<PoolBTCAddressInfo> {
  const apiUrl = `${xverseApiBaseUrl}/v1/pool/btc-address/info/${currency}`;
  const response = await axios.get(apiUrl, {
    method: 'GET',
  });
  return response.data;
}

export async function fetchStackingData(): Promise<StackingCycleData> {
  const apiUrl = `${xverseApiBaseUrl}/v1/pool/stacking-data/${currency}`;
  const response = await axios.get(apiUrl, {
    method: 'GET',
  });
  return response.data;
}

export async function fetchCurrentGeneratedReward(): Promise<CurrentRewardData> {
  const apiUrl = `${xverseApiBaseUrl}/v1/pool/current-reward/${currency}`;
  const response = await axios.get(apiUrl, {
    method: 'GET',
  });
  return response.data;
}

export async function fetchPoolParticipants(): Promise<number> {
  const apiUrl = `${xverseApiBaseUrl}/v1/pool/participants`;
  const response = await axios.get(apiUrl, {
    method: 'GET',
  });
  return response.data.poolParticipants;
}

export async function getConfirmedTransactions(
  stxAddress: string,
): Promise<StxTransactionListData> {
  const apiUrl = `${hiroMainnetBaseUrl}/extended/v1/address/${stxAddress}/transactions`;
  return axios
    .get<StxTransactionResponse>(apiUrl, {
      timeout: API_TIMEOUT_MILLI,
      params: {
        limit: 50,
        offset: 0,
      },
    })
    .then((response) => {
      const count: number = response.data.total;
      const transactions: StxTransactionData[] = [];
      response.data.results.forEach((tx) => {
        transactions.push(parseStxTransactionData(tx, stxAddress));
      });

      return {
        transactionsList: transactions,
        totalCount: count,
      };
    });
}

export async function getMempoolTransactions(
  stxAddress: string,
): Promise<StxMempoolTransactionListData> {
  const apiUrl = `${hiroMainnetBaseUrl}/extended/v1/tx/mempool?address=${stxAddress}`;
  const response = await axios.get<StxMempoolResponse>(apiUrl, {
    timeout: API_TIMEOUT_MILLI,
    params: {
      limit: 50,
      offset: 0,
    },
  });

  const count: number = response.data.total;
  const transactions: StxMempoolTransactionData[] = [];
  response.data.results.forEach((responseTx) => {
    transactions.push(parseMempoolStxTransactionsData(responseTx, stxAddress));
  });

  return {
    transactionsList: transactions,
    totalCount: count,
  };
}

export async function getRawTransaction(txId: string): Promise<string> {
  const apiUrl = `${hiroMainnetBaseUrl}/extended/v1/tx/${txId}/raw`;
  return axios.get<RawTransactionResponse>(apiUrl).then((response) => {
    return response.data.raw_tx;
  });
}

export async function getAccountBalance(txId: string): Promise<string> {
  const apiUrl = `${hiroMainnetBaseUrl}/extended/v1/tx/${txId}/raw`;
  return axios.get<RawTransactionResponse>(apiUrl).then((response) => {
    return response.data.raw_tx;
  });
}

export async function fetchCycleInfo(txId: string): Promise<string> {
  const apiUrl = `${hiroMainnetBaseUrl}/extended/v1/tx/${txId}/raw`;
  return axios.get<RawTransactionResponse>(apiUrl).then((response) => {
    return response.data.raw_tx;
  });
}

export async function fetchStackingStatus(stxAddress: string): Promise<StackerInfo> {
  const apiUrl = `${xverseApiBaseUrl}/v1/pool/${stxAddress}/status/web`;
  const response = await axios.get(apiUrl, {
    method: 'GET',
  });
  return response.data;
}
