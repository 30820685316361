import { useConnect } from '@stacks/connect-react';
import xverseLogo from '../assets/images/Full_Logo.svg';
import successElipse from '../assets/images/Elipse.svg';
import ArrowVector from '../assets/images/ArrowVector.svg';
import ArrowUpRight from '../assets/images/ArrowUpRight.svg';
import { TextButton, ImageButton } from './styled/Button';
import { RowFlex } from './styled/Flex';
import { useEffect, useContext, useState } from 'react';
import { LoginContext } from '../Context/LoginState';
import { PopUp } from './styled/Popup';
import { xverseSupportUrl } from '../utils/url.constants';
import { SubHeadingText } from './styled/Text';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  paddingLeft: 'auto',
  paddingRight: 'auto',
  paddingTop: 27,
  paddingBottom: 27,
});

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

const TransparentButton = styled.button((props) => ({
  ...props.theme.body_bold_m,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: 250,
  height: 40,
  marginRight: '5%',
  color: props.theme.colors.white,
  border: '1px solid rgba(255, 255, 255, 0.4)',
  background: 'transparent',
  borderRadius: 32,
  transition: 'opacity 0.1s ease',
  ':hover': {
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.6,
  },
}));

const LoggedInButton = styled.button((props) => ({
  ...props.theme.body_medium_m,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  height: 40,
  marginRight: '5%',
  padding: '12px 16px 12px 16px',
  color: props.theme.colors.white,
  background: 'rgba(255, 255, 255, 0.12)',
  borderRadius: 32,
  transition: 'opacity 0.1s ease',
  ':hover': {
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.6,
  },
}));

const HeaderButton = styled.button((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  marginLeft: 32,
  color: props.theme.colors.white,
  fontFamily: 'DMSans-Medium',
  fontSize: 15,
  transition: 'opacity 0.1s ease',
  ':hover': {
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.6,
  },
}));

function truncateAddress(address: string) {
  return address.substring(0, 4) + '...' + address.substring(37, 49);
}
interface HeaderProps {
  signOut: () => void;
  isMobile: boolean;
}

export const Header: React.FC<HeaderProps> = ({ signOut, isMobile }) => {
  const { doOpenAuth } = useConnect();
  const { userData } = useContext(LoginContext);
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [dropDownMenuVisibility, setDropDownMenuVisibility] = useState(false);
  const toggling = () => setDropDownMenuVisibility(!dropDownMenuVisibility);
  const isSignedIn = address !== '';

  useEffect(() => {
    if (userData) {
      setAddress(userData?.profile.stxAddress.mainnet);
    } else {
      setAddress('');
    }
  }, [userData]);

  function openDropDownMenu() {
    return (
      <PopUp
        width={150}
        height={100}
        marginRight={{ lg: '5%', xlg: '5%' }}
        marginTop={60}
        paddingLeft={3}
        state={dropDownMenuVisibility}
      >
        <TextButton
          color={'grey'}
          marginRight={5}
          marginBottom={3}
          onClick={() => {
            toggling();
            signOut();
            window.location.reload();
          }}
        >
          Disconnect
        </TextButton>
        <TextButton
          color={'grey'}
          marginRight={5}
          onClick={() => {
            toggling();
            doOpenAuth();
          }}
        >
          Switch Account
        </TextButton>
      </PopUp>
    );
  }
  function redirectToSupport() {
    window.open(xverseSupportUrl, '_blank', 'noopener,noreferrer');
  }

  if (isMobile) {
    return (
      <div>
        <img src={xverseLogo} style={{ display: 'block', padding: '1rem' }} alt="Logo" />
      </div>
    );
  } else {
    return (
      <>
        <HeaderContainer>
          {isSignedIn ? (
            <LoggedInButton onClick={toggling}>
              <RowFlex justifyContent="center" alignItems={'center'}>
                <img src={successElipse} width="10" height="10" alt="Success" />
                <SubHeadingText marginX={2}>{truncateAddress(address)}</SubHeadingText>
                <img src={ArrowVector} width="10" height="10" alt="Options" />
              </RowFlex>
            </LoggedInButton>
          ) : (
            <TransparentButton onClick={() => doOpenAuth()}>
              Connect Stacks Wallet
            </TransparentButton>
          )}
          <Container>
            <HeaderButton onClick={() => navigate('/')}>Pool</HeaderButton>
            {isSignedIn && (
              <HeaderButton onClick={() => navigate('/stacking')}>Dashboard</HeaderButton>
            )}
            <HeaderButton onClick={() => redirectToSupport()}>
              Support
              <img src={ArrowUpRight} alt="Arrow Up Right" />
            </HeaderButton>
          </Container>

          <ImageButton
            type="image"
            alt="Logo"
            src={xverseLogo}
            style={{
              marginLeft: '5%',
            }}
            onClick={() => {
              navigate('/');
            }}
          />
        </HeaderContainer>
        {dropDownMenuVisibility && openDropDownMenu()}
      </>
    );
  }
};
