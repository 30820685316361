import React, { FC } from 'react';
import styled from 'styled-components';

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  shadow,
  ShadowProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
  typography,
  TypographyProps,
  flex,
  alignItems,
  justifyContent,
} from 'styled-system';
import { FlexProps } from './Flex';

type ButtonStyleProps = BorderProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  ShadowProps &
  TypographyProps &
  FlexProps;

type ButtonProps = ButtonStyleProps &
  React.HTMLAttributes<HTMLButtonElement> & {
    children: String | React.ReactNode;
    disabled?: boolean;
  };

export const ButtonStyle = styled.button<ButtonStyleProps>`
  box-sizing: border-box;
  text-align: center;
  border-style: none;
  cursor: pointer;
  white-space: nowrap;
  transition: opacity 0.1s ease;
  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  :hover:enabled {
    opacity: 0.8;
  }
  :active:enabled {
    opacity: 0.6;
  }
  ${space}
  ${shadow}
    ${color}
    ${layout}
    ${border}
    ${typography}
    ${flex}
    ${alignItems}
    ${justifyContent}
`;
export const ConfirmDelegationButtonStyle = styled.button<ButtonStyleProps>`
  box-sizing: border-box;
  text-align: center;
  border-style: none;
  cursor: pointer;
  white-space: nowrap;
  ${space}
  ${shadow}
    ${color}
    ${layout}
    ${border}
    ${typography}
`;
export const RedirectButtonStyle = styled.button<ButtonStyleProps>`
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  :hover {
    text-decoration: underline;
  }
  ${space}
  ${shadow}
    ${color}
    ${layout}
    ${border}
    ${typography}
`;

export const ImageButton = styled.input<ButtonStyleProps>`
  :hover {
    opacity: 0.8;
  }
  cursor: pointer;
  ${space}
  ${shadow}
    ${color}
    ${layout}
    ${border}
    ${typography}
`;

export const AppDownloadImageButton = styled.button<ButtonStyleProps>`
  margin-right: 12px;
  background: transparent;
  transition: opacity 0.1s ease;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.6;
  }
`;

export const Button: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyle
      color="white"
      width={282}
      height={50}
      borderRadius={8}
      fontFamily="Satoshi-Medium"
      bg="purple"
      fontSize={14}
      {...props}
    >
      {props.children}
    </ButtonStyle>
  );
};

export const StackingButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyle
      color="black"
      width={250}
      marginY={40}
      height={50}
      borderRadius={8}
      fontFamily="Satoshi-Medium"
      bg="action"
      fontSize={14}
      {...props}
    >
      {props.children}
    </ButtonStyle>
  );
};

export const ConfirmDelegationButton: FC<ButtonProps> = (props) => {
  return (
    <ConfirmDelegationButtonStyle
      color="black"
      marginY={20}
      width={155}
      height={48}
      borderRadius={8}
      fontFamily="Satoshi-Medium"
      bg="action"
      fontSize={14}
      {...props}
    >
      {props.children}
    </ConfirmDelegationButtonStyle>
  );
};
export const PrimaryButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyle
      color="black"
      width={336}
      height={48}
      borderRadius={8}
      fontFamily="Satoshi-Medium"
      bg="action"
      fontSize={14}
      {...props}
    >
      {props.children}
    </ButtonStyle>
  );
};

export const TransparentSquareButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyle
      display="inline-block"
      color="white"
      width={240}
      height={48}
      border="1px solid rgba(255, 255, 255, 0.4)"
      borderRadius={8}
      bg="transparent"
      fontSize={14}
      fontFamily="Satoshi-Medium"
      overflow="hidden"
      {...props}
    >
      {props.children}
    </ButtonStyle>
  );
};
export const TextButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyle
      color="white"
      width="fit-content"
      height={18}
      bg="transparent"
      fontSize={13}
      display="inline-block"
      lineHeight="140%"
      fontFamily="Satoshi-Medium"
      {...props}
    >
      {props.children}
    </ButtonStyle>
  );
};

export const RedirectButton: FC<ButtonProps> = (props) => {
  return (
    <RedirectButtonStyle
      color="white"
      textAlign="left"
      bg="transparent"
      display={{ s: 'block', md: 'block', lg: 'inline-block' }}
      lineHeight="140%"
      fontFamily="DMSans-Regular"
      fontSize={14}
      marginTop={32}
      {...props}
    >
      {props.children}
    </RedirectButtonStyle>
  );
};

export const StackingCycleButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyle
      display="inline-block"
      color="white"
      width={46}
      height={46}
      border="1px solid rgba(255, 255, 255, 0.8)"
      borderRadius={80}
      bg="transparent"
      marginTop={3}
      overflow="hidden"
      {...props}
    >
      {props.children}
    </ButtonStyle>
  );
};
