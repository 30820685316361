import styled from 'styled-components';
import { StatsCard } from './StatsCard';
import FlagBanner from '../../../assets/images/FlagBanner.svg';
import FlagCheckered from '../../../assets/images/FlagCheckered.svg';
import Seperator from '../../../components/Seperator';
import BarLoader from '../../../components/styled/BarLoader';
import { LoaderSize } from '../../../utils/constants';
import { Theme } from '../../../Theme';
import { HeadingText } from '../../../components/styled/Text';
import { Container } from '../../../components/styled/Container';
import { StackingData } from '../../../utils/data.type';

interface GridProps {
  isMobile: boolean;
}
interface Props {
  isMobile: boolean;
  stxLocked: number;
  nextCycleEndsIn: string;
  totalReward: number;
  currCycleEndsIn: string;
  currCycleBegan: string;
  stackingData: StackingData;
}
const GridContainer = styled.div<GridProps>((props) => ({
  display: 'grid',
  columnGap: 15,
  rowGap: 30,
  gridTemplateColumns: props.isMobile ? 'auto auto' : '30% 14% auto auto auto ',
  gridTemplateRows: props.isMobile ? 'auto auto' : 'auto',
}));

export const CycleStats = ({
  isMobile,
  stxLocked,
  nextCycleEndsIn,
  totalReward,
  currCycleEndsIn,
  currCycleBegan,
  stackingData,
}: Props) => {
  return (
    <>
      <GridContainer isMobile={isMobile}>
        {stackingData?.poolInfo ? (
          <HeadingText
            gridColumn={{ s: 'span 2', md: 'span 1', lg: 'span 1' }}
            fontSize={{ s: 21, md: 21, lg: 28 }}
          >{`Current Cycle #${stackingData?.poolInfo?.pox?.current_cycle?.id}`}</HeadingText>
        ) : (
          <BarLoader loaderSize={LoaderSize.LARGE} color={Theme.colors.background} margin={'0'} />
        )}
        <StatsCard
          title="Cycle began"
          value={`${currCycleBegan} ago`}
          image={FlagBanner}
          showLoader={!stackingData?.poolInfo}
        />
        <StatsCard
          title="Estimated to end in"
          value={currCycleEndsIn}
          image={FlagCheckered}
          showLoader={!stackingData?.poolInfo}
        />
        <StatsCard
          title="Rewards this cycle"
          value={`${totalReward} BTC`}
          showLoader={!stackingData?.poolInfo}
        />
        <StatsCard
          title="Total stacked"
          value={stxLocked.toString()}
          displayNumberFormat
          showLoader={!stackingData?.poolInfo}
        />
      </GridContainer>
      <Container marginY={{ s: 40, md: 45, lg: 65 }}>
        <Seperator />
      </Container>

      <GridContainer isMobile={isMobile}>
        {stackingData?.poolInfo ? (
          <HeadingText
            gridColumn={{ s: 'span 2', md: 'span 1', lg: 'span 1' }}
            fontSize={{ s: 21, md: 21, lg: 28 }}
          >{`Next Cycle #${stackingData?.poolInfo?.pox?.next_cycle?.id}`}</HeadingText>
        ) : (
          <BarLoader loaderSize={LoaderSize.LARGE} color={Theme.colors.background} margin={'0'} />
        )}
        <StatsCard
          title="Cycle starts in"
          value={currCycleEndsIn}
          image={FlagBanner}
          showLoader={!stackingData?.poolInfo}
        />
        <StatsCard
          title="Estimated to end in"
          value={nextCycleEndsIn}
          image={FlagCheckered}
          showLoader={!stackingData?.poolInfo}
        />
      </GridContainer>
    </>
  );
};
