import { useEffect, useState } from 'react';
import { fetchCoreInfo, fetchStackingData } from '../utils/api';
import { CoreInfo, StackingData } from '../utils/data.type';
import { blocksToTime } from '../utils/wallet.utils';

export default function usePoolCycleData(stackingData: StackingData) {
  const [currCycleBegan, setCurrCycleBegan] = useState<string>('');
  const [stxLocked, setStxLocked] = useState<number>(0);

  useEffect(() => {
    const fetchInfo = async () => {
      const data = await fetchStackingData();
      if (data) {
        setStxLocked(Number(data?.poolStxLocked?.totalStxLocked?.toFixed(2)));
        let currentBurnBlockHeight = stackingData?.coreInfo?.burn_block_height!;
        if (!currentBurnBlockHeight) {
          const coreInfo: CoreInfo = await fetchCoreInfo();
          currentBurnBlockHeight = coreInfo?.burn_block_height;
        }
        const startingBlockHeight = data?.cycleStartHeightInBlocks;
        setCurrCycleBegan(blocksToTime(currentBurnBlockHeight - startingBlockHeight));
      }
    };
    fetchInfo();
  }, [stackingData]);

  return {
    currCycleBegan,
    stxLocked,
  };
}
