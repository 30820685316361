import styled from 'styled-components';

const Line = styled.div((props) => ({
  border: `0.5px solid #303354`,
}));

function Seperator() {
  return <Line />;
}

export default Seperator;
