import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { ColorProps, space, SpaceProps, layout, LayoutProps, BorderProps } from 'styled-system';

type PopupStyleProps = BorderProps & SpaceProps & ColorProps & LayoutProps;

type Props = {
  state: boolean;
};
type PopUpProps = Props &
  PopupStyleProps & {
    onClick?: () => void;
    children?: any;
    id?: string;
  };

const StyledPopup = styled(Popup)<PopupStyleProps>`
  &-overlay {
    width: 327px;
    height: 266px;
    margin-left: auto;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: auto;
    background: #1d2032;
    box-shadow: 0px 12px 52px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    transition: opacity 500ms;
    ${space}
    ${layout}
  }
  &-content {
    padding: 1px;
  }
`;

export const PopUp: React.FC<PopUpProps> = (props) => (
  <StyledPopup open={props.state} modal position="top center" {...props}>
    {props.children}
  </StyledPopup>
);
