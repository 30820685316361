import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import { fetchPoolBtcAddressInfo } from '../utils/api';
import { DelegatedStxData, StackingData, StackingStatus } from '../utils/data.type';
import {
  blocksToTime,
  getBtcFiatEquivalent,
  getStackerCyclesValue,
  getTimeOfCycleEnd,
  roundBtc,
  stxToMicrostacks,
} from '../utils/wallet.utils';

export function useCycleData(
  stackingStatus: StackingStatus,
  address: string,
  stackingData: StackingData,
) {
  const [nextPayout, setNextPayout] = useState('');
  const [currCycleEndsIn, setCycleEndsIn] = useState('');
  const [poolCloseIn, setPoolCloseIn] = useState('');
  const [nextCycleEndsIn, setNextCycleEndsIn] = useState('');
  const [cyclesValue, setCyclesValue] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);

  const poolAvailable = stackingData?.poolInfo?.pools?.length > 0;

  const getProgressPercent = useCallback(() => {
    if (stackingData && stackingData.stackerInfo && stackingData.stackerInfo!.stacked) {
      const currentBurnBlockHeight = stackingData?.coreInfo?.burn_block_height!;
      const rewardCycleLength = stackingData?.poolInfo?.pox.reward_cycle_length || 2100;
      const firstRewardCycle = stackingData.poolInfo?.pox?.current_cycle?.id;

      const startingBurnBlock =
        stackingData?.poolInfo?.pox?.first_burnchain_block_height! +
        firstRewardCycle * stackingData?.poolInfo?.pox?.reward_cycle_length! -
        100;

      const progressBlocks = Math.max(currentBurnBlockHeight - startingBurnBlock, 0);
      const progressPercent = Math.min(Math.floor((progressBlocks / rewardCycleLength) * 100), 100);
      setProgressPercent(progressPercent);
    } else {
      setProgressPercent(0);
    }
  }, [stackingData]);

  const getNextCycleTime = useCallback(async () => {
    setCycleEndsIn(getTimeOfCycleEnd(stackingData?.poolInfo));
    var poolCloseBlocks = 0;
    if (stackingData?.poolInfo?.pox?.next_reward_cycle_in! <= 100) {
      poolCloseBlocks = poolAvailable
        ? stackingData?.poolInfo?.pox?.next_reward_cycle_in! +
          stackingData?.poolInfo?.pox?.reward_cycle_length! -
          stackingData?.poolInfo?.enrollment_closing_blocks!
        : 0;
    } else {
      poolCloseBlocks = poolAvailable
        ? stackingData?.poolInfo?.pox?.next_reward_cycle_in! -
          stackingData?.poolInfo?.enrollment_closing_blocks!
        : 0;
    }
    setPoolCloseIn(blocksToTime(poolCloseBlocks));
  }, [poolAvailable, stackingData]);

  const getNextCycleEndsInTime = useCallback(async () => {
    const blocksUntilRewardPhase = poolAvailable
      ? stackingData?.poolInfo?.pox?.next_cycle?.blocks_until_reward_phase
      : 0;

    const rewardPhaseBlockLength = stackingData?.poolInfo?.pox?.reward_phase_block_length ?? 0;

    setNextCycleEndsIn(blocksToTime(blocksUntilRewardPhase + rewardPhaseBlockLength));
  }, [poolAvailable, stackingData]);

  const getNextPayoutTime = useCallback(async () => {
    if (stackingData && stackingData.poolInfo && stackingData.poolInfo.pox) {
      if (stackingData.stackerInfo?.stacked) {
        const nextRewardCycleIn = stackingData.poolInfo.pox.next_reward_cycle_in;
        setNextPayout(`~${blocksToTime(nextRewardCycleIn)}`);
      } else {
        const nextRewardCycleIn =
          stackingData?.poolInfo.pox.next_reward_cycle_in +
          stackingData?.poolInfo.pox.reward_cycle_length;
        setNextPayout(`~${blocksToTime(nextRewardCycleIn)}`);
      }
    }
  }, [stackingData]);

  useEffect(() => {
    const cyclesValue = getStackerCyclesValue({
      poolInfo: stackingData?.poolInfo,
      stackerInfo: stackingData?.stackerInfo,
    });
    setCyclesValue(cyclesValue);

    getProgressPercent();
    getNextCycleTime();
    getNextPayoutTime();
    getNextCycleEndsInTime();
  }, [
    stackingData,
    address,
    stackingStatus,
    getProgressPercent,
    getNextCycleTime,
    getNextCycleEndsInTime,
    getNextPayoutTime,
  ]);

  const lockedAmount = stackingStatus?.delegatedStxData?.lockedAmount;
  const delegatedAmount = stackingStatus?.delegatedStxData?.delegatedAmount;
  const delegateToAddress = stackingStatus?.delegatedStxData?.delegatedToAddress;
  const rewardAddress = stackingStatus?.delegatedStxData?.poxBtcAddress;

  return {
    progressPercent,
    currCycleEndsIn,
    poolCloseIn,
    cyclesValue,
    delegateToAddress,
    lockedAmount,
    delegatedAmount,
    rewardAddress,
    nextPayout,
    nextCycleEndsIn,
  };
}

export function useGeneratedCycleBtcReward(
  stackingData: StackingData,
  lockedAmount: DelegatedStxData['lockedAmount'],
) {
  const [generatedRewardInBtc, setGeneratedRewardInBtc] = useState('0');
  const [generatedRewardInCurrency, setGeneratedRewardInCurrency] = useState<any>(0);
  useEffect(() => {
    const getRewardValue = async () => {
      if (stackingData && stackingData?.stackerInfo && stackingData?.stackerInfo.stacked) {
        const poolBtcBalance = await fetchPoolBtcAddressInfo();
        const btcPoolBalance = poolBtcBalance?.poolBtcAddressBalance;
        const poolBalance = stackingData?.stackerInfo?.pool_total;
        if (poolBalance && lockedAmount) {
          const poolTotal = new BigNumber(poolBalance);
          if (!poolTotal.isZero()) {
            const amount = stxToMicrostacks(new BigNumber(lockedAmount));
            const reward = roundBtc(amount.dividedBy(poolTotal).multipliedBy(btcPoolBalance));
            setGeneratedRewardInBtc(reward);
            const generatedRewardInCurrency = await getBtcFiatEquivalent(
              new BigNumber(Number(reward)),
            );
            setGeneratedRewardInCurrency(generatedRewardInCurrency.toNumber().toFixed(2));
          }
        }
      }
    };
    getRewardValue();
  }, [stackingData, lockedAmount]);

  return {
    generatedRewardInBtc,
    generatedRewardInCurrency,
  };
}
