import { StacksMainnet } from '@stacks/network';
import { ContractCallRegularOptions, openContractCall } from '@stacks/connect';
import { address } from 'bitcoinjs-lib';
import BigNumber from 'bignumber.js';
import {
  contractPrincipalCV,
  noneCV,
  AddressHashMode,
  bufferCV,
  tupleCV,
  AnchorMode,
  uintCV,
  standardPrincipalCV,
  someCV,
  PostConditionMode,
  ClarityType,
  TupleCV,
  UIntCV,
  hexToCV,
  SomeCV,
  PrincipalCV,
  cvToString,
  cvToHex,
} from '@stacks/transactions';
import BN from 'bn.js';
import { Dispatch } from 'react';
import {
  ALLOW_CONTRACT_CALL_FUNCTION_NAME,
  appDetails,
  DELEGATE_STX_FUNCTION_NAME,
  POX_CONTRACT_ADDRESS,
  POX_CONTRACT_NAME,
  REVOKE_DELEGATION_FUNCTION_NAME,
  StackingState,
} from './constants';
import { hiroMainnetBaseUrl } from './url.constants';
import { poxAddressToTuple } from '@stacks/stacking';

import Mixpanel from 'mixpanel';
import { Action, ActionType, DelegationInfo, DelegatedStxData } from './data.type';

// create an instance of the mixpanel client
export const mixpanel = Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

export const generateUnsignedAllowContractCallerTransaction = async (
  stackAddress: string,
  poxContractAddress: string,
  poxContractName: string,
  poolContractAddress: string,
  contractName: string,
) => {
  const txOptions = {
    appDetails: appDetails,
    contractAddress: poxContractAddress,
    contractName: poxContractName,
    functionName: ALLOW_CONTRACT_CALL_FUNCTION_NAME,
    functionArgs: [contractPrincipalCV(poolContractAddress, contractName), noneCV()],
    senderKey: stackAddress,
    validateWithAbi: true,
    network: new StacksMainnet(),
    postConditions: [],
    anchorMode: AnchorMode.Any,

    onFinish: (data: any) => {
      return data;
    },
  };
  await openContractCall(txOptions);
};

export const delegateStacks = async (
  stackAddress: string,
  poolAdminAddress: string,
  contractAddress: string,
  contractName: string,
  amount: BigNumber,
  rewardAddress: string,
  poolRewardAddress: string,
  dispatchStackingStatus: Dispatch<Action>,
) => {
  const poolRewardAddressTuple = poxAddressToTuple(poolRewardAddress);
  const userRewardAddressTuple = poxAddressToTuple(rewardAddress);

  const AMOUNT = amount.multipliedBy(1000000).toNumber();
  const functionArgs = [
    uintCV(AMOUNT),
    standardPrincipalCV(poolAdminAddress),
    noneCV(),
    someCV(poolRewardAddressTuple),
    userRewardAddressTuple,
    noneCV(),
  ];
  const txOptions: ContractCallRegularOptions = {
    appDetails: appDetails,
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: DELEGATE_STX_FUNCTION_NAME,
    functionArgs: functionArgs,
    senderKey: stackAddress,
    network: new StacksMainnet(),
    postConditions: [],
    postConditionMode: PostConditionMode.Deny,
    anchorMode: AnchorMode.Any,

    onFinish: () => {
      const delegatedData: DelegatedStxData = {
        delegatedAmount: amount,
        lockedAmount: BigNumber(0),
        poxBtcAddress: rewardAddress,
        delegatedToAddress: poolAdminAddress,
        hasPendingDelegate: true,
      };
      dispatchStackingStatus({
        type: ActionType.SET_DELEGATING,
        payload: { stackingState: StackingState.Pending, delegatedStxData: delegatedData },
      });
    },
  };
  const transaction = await openContractCall(txOptions);
  return transaction;
};

export function addressToVersionHashbyteTupleCV(btcAddress: string) {
  const { hashMode, data } = decodeBtcAddress(btcAddress);
  const hashModeBuffer = bufferCV(new BN(hashMode, 10).toArrayLike(Buffer));
  const hashbytes = bufferCV(data);
  const address = tupleCV({
    hashbytes,
    version: hashModeBuffer,
  });
  return address;
}

export function decodeBtcAddress(btcAddress: string) {
  const hashMode = getAddressHashMode(btcAddress);
  if (btcAddress.startsWith('bc1') || btcAddress.startsWith('tb1')) {
    const { data } = address.fromBech32(btcAddress);
    return {
      hashMode,
      data,
    };
  } else {
    const { hash } = address.fromBase58Check(btcAddress);
    return {
      hashMode,
      data: hash,
    };
  }
}

function getAddressHashMode(btcAddress: string) {
  if (btcAddress.startsWith('bc1') || btcAddress.startsWith('tb1')) {
    const { data } = address.fromBech32(btcAddress);
    if (data.length === 32) {
      return AddressHashMode.SerializeP2WSH;
    } else {
      return AddressHashMode.SerializeP2WPKH;
    }
  } else {
    const { version } = address.fromBase58Check(btcAddress);
    switch (version) {
      case 0:
        return AddressHashMode.SerializeP2PKH;
      case 111:
        return AddressHashMode.SerializeP2PKH;
      case 5:
        return AddressHashMode.SerializeP2SH;
      case 196:
        return AddressHashMode.SerializeP2SH;
      default:
        throw new Error('Invalid pox address version');
    }
  }
}

export const generateUnsignedRevokeTransaction = async (
  stackAddress: string,
  poxContractAddress: string,
  poxContractName: string,
  dispatch: Dispatch<Action>,
) => {
  const txOptions = {
    appDetails: appDetails,
    contractAddress: poxContractAddress,
    contractName: poxContractName,
    functionName: REVOKE_DELEGATION_FUNCTION_NAME,
    functionArgs: [],
    senderKey: stackAddress,
    validateWithAbi: true,
    network: new StacksMainnet(),
    postConditions: [],
    anchorMode: AnchorMode.Any,
    onFinish: () => {
      dispatch({ type: ActionType.SET_NOT_STACKING });
    },
  };
  const transaction = await openContractCall(txOptions);
  return transaction;
};

export async function fetchDelegationState(stxAddress: string): Promise<DelegationInfo> {
  const mapName = 'delegation-state';
  const mapEntryPath = `/${POX_CONTRACT_ADDRESS}/${POX_CONTRACT_NAME}/${mapName}`;
  const apiUrl = `${hiroMainnetBaseUrl}/v2/map_entry${mapEntryPath}?proof=0`;
  const key = cvToHex(tupleCV({ stacker: standardPrincipalCV(stxAddress) }));
  return fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify(key),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json())
    .then((response) => {
      const responseCV = hexToCV(response['data']);
      if (responseCV.type === ClarityType.OptionalNone) {
        return {
          delegated: false,
        };
      } else {
        const someCV = responseCV as SomeCV;
        const tupleCV = someCV.value as TupleCV;
        const amount: UIntCV = tupleCV.data['amount-ustx'] as UIntCV;
        const delegatedTo: PrincipalCV = tupleCV.data['delegated-to'] as PrincipalCV;
        const untilBurnHeightSomeCV: SomeCV = tupleCV.data['until-burn-ht'] as SomeCV;
        let untilBurnHeight;
        if (untilBurnHeightSomeCV.type === ClarityType.OptionalSome) {
          const untilBurnHeightUIntCV: UIntCV = untilBurnHeightSomeCV.value as UIntCV;
          untilBurnHeight = Number(untilBurnHeightUIntCV.value);
        }
        const delegatedAmount = new BigNumber(amount.value.toString());

        const delegationInfo: DelegationInfo = {
          delegated: true,
          amount: delegatedAmount.toString(),
          delegatedTo: cvToString(delegatedTo),
          untilBurnHeight: untilBurnHeight,
        };
        return delegationInfo;
      }
    });
}
