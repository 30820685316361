import { ColumnFlex, RowFlex } from '../../../components/styled/Flex';
import NumberFormat from 'react-number-format';
import {
  BodyText,
  CurrencyText,
  BoldHeadingText,
  MediumBodyText,
  StackingBodyText,
  StackingHeadingText,
  SubHeadingText,
} from '../../../components/styled/Text';
import { Theme } from '../../../Theme';
import InfoVector from '../../../assets/images/Info.svg';
import { SetStateAction, useEffect, useState, useContext, Dispatch } from 'react';
import { ConfirmDelegationButton } from '../../../components/styled/Button';
import {
  delegateStacks,
  generateUnsignedAllowContractCallerTransaction,
} from '../../../utils/transaction';
import {
  blocksToTime,
  getStackerCyclesValue,
  getStacksRates,
  getTimeOfCycleEnd,
  microstacksToStx,
  validateBtcAddress,
} from '../../../utils/wallet.utils';
import BigNumber from 'bignumber.js';
import {
  Action,
  DelegatedStxData,
  Rates,
  StackingData,
  StackingStatus,
} from '../../../utils/data.type';
import { LoginContext } from '../../../Context/LoginState';
import StackingInfoTile from '../StackingInfoTile';
import { useNavigate } from 'react-router-dom';
import { LoaderSize, REDEMPTION_FEES, StartStackingSteps } from '../../../utils/constants';
import useUserBalance from '../../../hooks/useUserBalance';
import BarLoader from '../../../components/styled/BarLoader';
import {
  AddressInputField,
  AlertContainer,
  AmountInputFieldRow,
  AmountRowContainer,
  Container,
  InputFieldContainer,
  LoaderContainer,
  StackingButton,
  TickerText,
  TransparentButton,
} from './components';

interface Props {
  stackingData: StackingData;
  userRewardAddress?: string;
  lockedAmount: DelegatedStxData['lockedAmount'];
  delegatedAmount: DelegatedStxData['delegatedAmount'];
  stackingStatus: StackingStatus;
  isDelegated: boolean;
  dispatchStackingStatus: Dispatch<Action>;
  onFinishStackMore: () => void;
}
export const StartStacking = ({
  stackingData,
  userRewardAddress,
  lockedAmount,
  delegatedAmount,
  stackingStatus,
  isDelegated,
  dispatchStackingStatus,
  onFinishStackMore,
}: Props) => {
  const { availableBalance, loading } = useUserBalance();
  const { userData } = useContext(LoginContext);
  const navigate = useNavigate();
  const pox = stackingData?.poolInfo?.pox;
  const poxContractAddress = pox?.contract_id?.split('.')[0]!;
  const poxContractName = pox?.contract_id?.split('.')[1]!;
  const address = userData?.profile.stxAddress.mainnet;
  // Use either Leather or Xverse returned BTC address, otherwise fallback to empty string
  const [rewardAddress, setRewardAddess] = useState(
    userData?.profile?.btcAddress?.p2wpkh?.mainnet ?? userData?.profile?.btcAddress ?? '',
  );
  const [stxAmount, setStxAmount] = useState<number>(0);
  const [displayStxAmount, setDisplayStxAmount] = useState<string>('');
  const [showNotEnoughFundsAlert, setShowNotEnoughFundsAlert] = useState<boolean>(false);
  const [showMinimumAlert, setshowMinimumAlert] = useState<boolean>(false);
  const [showEmptyStxAlert, setShowEmptyStxAlert] = useState<boolean>(false);
  const [missingRewardAddressError, setmissingRewardAddressError] = useState<boolean>(false);
  const [confirmDelegationOpacity, setconfirmDelegationOpacity] = useState<string>('50%');
  const [allowContractOpacity, setAllowContractOpacity] = useState<string>('100%');
  const [stxAmountFiatRate, displayStxAmountFiatRate] = useState<string | undefined>(undefined);
  const [currentStepIndex, setCurrentStepIndex] = useState<StartStackingSteps>(
    userRewardAddress ? StartStackingSteps.StepTwo : StartStackingSteps.StepOne,
  );
  const [rates, setRates] = useState<Rates | null>(null);

  const pool = stackingData?.poolInfo?.pools?.[0];
  const poolYield = pool?.estimated_yield ?? 0;
  const poolRewardAddress = pool?.reward_address ?? '';
  const poolAvailable = Boolean(pool);
  const isPoolClosed = !pool || !stackingData?.poolInfo?.open;
  const isStackMore = Boolean(userRewardAddress);
  const isReplacingStackMore = isStackMore && delegatedAmount.gt(lockedAmount);

  const lockedAmountPlusStxAmount = isStackMore
    ? lockedAmount.plus(stxAmount)
    : BigNumber(stxAmount);

  const cyclesValue = getStackerCyclesValue({
    poolInfo: stackingData?.poolInfo,
    stackerInfo: stackingData?.stackerInfo,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const rates = await getStacksRates();
        setRates(rates);
      } catch (error) {}
    }
    fetchData();
  }, []);

  const handleClickNext = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const handleClickBack = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const handleConfirmDelegationClickBack = () => {
    if (isStackMore) {
      return setCurrentStepIndex(StartStackingSteps.StepTwo);
    }

    handleClickBack();
  };

  const handleAmountClickNext = () => {
    if (!checkAmount(stxAmount)) {
      return;
    }

    if (isStackMore) {
      return setCurrentStepIndex(StartStackingSteps.StepFour);
    }

    handleClickNext();
  };

  const handleRecipientClickNext = () => {
    if (isAddressValid(rewardAddress)) {
      handleClickNext();
    }
  };

  const getStxAmountFiatRate = (stxAmount: BigNumber) =>
    Boolean(rates)
      ? stxAmount.multipliedBy(rates.stxBtcRate).multipliedBy(rates.btcFiatRate)
      : BigNumber(0);

  const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    const resultRegex = /[^0-9.]/g;
    let formattedValue = parseFloat(newValue.replace(resultRegex, ''));
    if (isNaN(formattedValue)) {
      formattedValue = 0;
      setDisplayStxAmount('');
    } else if (formattedValue > 1000000000) {
      formattedValue = formattedValue - 1;
    } else {
      setDisplayStxAmount(formattedValue.toString());
    }
    setStxAmount(formattedValue);
    checkAmount(formattedValue);
    const stxAmountFiatRate = getStxAmountFiatRate(BigNumber(formattedValue));
    if (stxAmountFiatRate.gt(0)) displayStxAmountFiatRate(stxAmountFiatRate.toFixed(2));
  };

  const renderInfoSection = (description: string, title?: string) => {
    return (
      <AlertContainer>
        <img src={InfoVector} alt="Info Vector" height={24} width={24} />
        <ColumnFlex marginLeft={18}>
          {title && <BoldHeadingText marginBottom={2}>{title}</BoldHeadingText>}
          <MediumBodyText>{description}</MediumBodyText>
        </ColumnFlex>
      </AlertContainer>
    );
  };

  const isAddressValid = (address: string): boolean => {
    if (address === '' || !validateBtcAddress(address)) {
      setmissingRewardAddressError(true);
      return false;
    } else {
      setmissingRewardAddressError(false);
      return true;
    }
  };

  const checkAmount = (amount: number) => {
    let isAmountValid = true;
    const minimumStx = microstacksToStx(new BigNumber(pool!.minimum));
    const isMoreThanPoolMinimum = amount !== 0 && minimumStx && amount < Number(minimumStx);
    if (amount === 0) {
      setShowEmptyStxAlert(true);
      isAmountValid = false;
    } else setShowEmptyStxAlert(false);
    if (!isStackMore && isMoreThanPoolMinimum) {
      setshowMinimumAlert(true);
      isAmountValid = false;
    } else setshowMinimumAlert(false);
    if (amount > Number(availableBalance) || amount + REDEMPTION_FEES > Number(availableBalance)) {
      setShowNotEnoughFundsAlert(true);
      isAmountValid = false;
    } else setShowNotEnoughFundsAlert(false);
    return isAmountValid;
  };

  const stackStx = async () => {
    if (pool && confirmDelegationOpacity === '100%') {
      const userRewardAddressParam = isStackMore ? userRewardAddress : rewardAddress;
      await delegateStacks(
        address,
        pool?.admin_address,
        pool?.contract_address,
        pool?.contract,
        lockedAmountPlusStxAmount,
        userRewardAddressParam,
        pool.reward_address,
        dispatchStackingStatus,
      );
      if (isStackMore) {
        onFinishStackMore();
      }
    }
  };

  const renderStxInputField = () => {
    return showEmptyStxAlert || showMinimumAlert || showNotEnoughFundsAlert ? (
      <InputFieldContainer isError={true}>
        <AmountInputFieldRow value={displayStxAmount} onChange={onChange}></AmountInputFieldRow>
        <TickerText>STX</TickerText>
      </InputFieldContainer>
    ) : (
      <InputFieldContainer>
        <AmountInputFieldRow value={displayStxAmount} onChange={onChange}></AmountInputFieldRow>
        <TickerText>STX</TickerText>
      </InputFieldContainer>
    );
  };

  const renderRewardAddressField = () => {
    if (missingRewardAddressError) {
      return (
        <>
          <AddressInputField
            isError
            placeholder="Type or paste your Bitcoin Address"
            onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setRewardAddess(e.target.value);
              isAddressValid(e.target.value.toString());
            }}
            value={rewardAddress}
          ></AddressInputField>
          <BodyText color="red" fontSize={12} marginTop={7.8}>
            You need to enter a valid BTC address
          </BodyText>
        </>
      );
    }

    return (
      <AddressInputField
        placeholder="Bitcoin address"
        onChange={(e: { target: { value: SetStateAction<string> } }) => {
          setRewardAddess(e.target.value);
          isAddressValid(e.target.value.toString());
        }}
        value={rewardAddress}
      ></AddressInputField>
    );
  };

  const renderLockingStxForNextCycleSection = () => {
    return (
      <Container>
        <StackingHeadingText marginTop={128}>Lock STX for the Next Cycle</StackingHeadingText>
        <StackingBodyText marginTop={16}>
          Your STX will be locked once the next cycle starts
        </StackingBodyText>
        <StackingBodyText marginTop={16}>
          When your delegation transaction is confirmed by the network, you're all set. You may need
          to wait up to a few days for the next cycle to begin.
        </StackingBodyText>
        <StackingButton onClick={handleClickNext}>I understand</StackingButton>
      </Container>
    );
  };

  const currCycleEndsIn = getTimeOfCycleEnd(stackingData?.poolInfo);

  const renderStackMoreAmountSection = () => {
    const amountHasError = showEmptyStxAlert || showNotEnoughFundsAlert;

    return (
      <RowFlex>
        <ColumnFlex width={500} marginRight={128}>
          <StackingHeadingText marginTop={128}>Stack more for the next cycle</StackingHeadingText>
          <StackingBodyText marginTop={16}>
            Enter the amount you want to add for Stacking in the next cycle. Any new STX you add
            will start generating rewards once the next cycle starts, in approximately{' '}
            <SubHeadingText display="inline-flex">{currCycleEndsIn}</SubHeadingText>.
          </StackingBodyText>
          <RowFlex>
            <SubHeadingText marginTop={32}>Amount</SubHeadingText>
            <AmountRowContainer>
              <SubHeadingText marginTop={32} color={'lightGrey'}>
                Available balance:
              </SubHeadingText>
              {loading ? (
                <LoaderContainer>
                  <BarLoader
                    loaderSize={LoaderSize.MEDIUM}
                    color={Theme.colors.background}
                    margin={'0'}
                  />
                </LoaderContainer>
              ) : (
                <NumberFormat
                  value={availableBalance.toString()}
                  displayType={'text'}
                  thousandSeparator={true}
                  suffix=" STX"
                  renderText={(value: string) => (
                    <SubHeadingText marginTop={32} marginLeft={10}>
                      {value}
                    </SubHeadingText>
                  )}
                />
              )}
            </AmountRowContainer>
          </RowFlex>
          {renderStxInputField()}
          <CurrencyText marginTop={2}>
            {stxAmountFiatRate ? `~ $ ${stxAmountFiatRate} USD` : `~ $ 0 USD`}
          </CurrencyText>
          {showEmptyStxAlert && (
            <BodyText color="red" fontSize={12} marginTop={7.8}>
              You need to enter a valid STX amount
            </BodyText>
          )}
          {showNotEnoughFundsAlert && (
            <BodyText color="red" fontSize={12} marginTop={7.8}>
              You don't have enough funds
            </BodyText>
          )}
          {/* TODO: enable this when we have a proper solution for the locked!==delegated issue */}
          {/* {!amountHasError && isReplacingStackMore && (
            <RowFlex justifyContent="flex-start" alignItems="center" marginTop={7.8}>
              <img src={WarningOctagon} style={{ marginRight: 4 }} alt="Warning" />
              <BodyText color="yellow" fontSize={12}>
                This amount will replace your current {delegatedAmount.toString()} STX increase.
              </BodyText>
            </RowFlex>
          )} */}
          <StackingBodyText marginTop={16}>
            You will be stacking a total of {lockedAmount.toString()} + {stxAmount} ={' '}
            {lockedAmountPlusStxAmount.toString()} STX
          </StackingBodyText>
          {renderInfoSection('Be sure to keep enough STX for delegation and redemption fees.')}
          <RowFlex justifyContent="flex-start">
            <TransparentButton onClick={onFinishStackMore}>Previous</TransparentButton>
            <StackingButton disabled={amountHasError || !stxAmount} onClick={handleAmountClickNext}>
              Confirm
            </StackingButton>
          </RowFlex>
        </ColumnFlex>
        <StackingInfoTile
          lockedAmount={lockedAmount}
          delegatedAmount={delegatedAmount}
          stxAmountFiatRate={getStxAmountFiatRate(lockedAmount).toString()}
          poolYield={poolYield}
          cyclesValue={cyclesValue}
          rewardAddress={isStackMore ? userRewardAddress : rewardAddress}
          isPoolClosed={isPoolClosed}
          delegatedToAddress={stackingData?.delegationInfo?.delegatedTo}
          poolAddress={poolRewardAddress}
          stackingState={stackingStatus.stackingState}
          isDelegated={isDelegated}
          hideActions
        />
      </RowFlex>
    );
  };

  function renderEnterAmountSection() {
    return (
      <ColumnFlex width={500}>
        <StackingHeadingText marginTop={128}>Enter an amount</StackingHeadingText>
        <StackingBodyText marginTop={16}>
          Enter the amount you want to lock for Stacking. The current minimum to participate is 100
          STX.
        </StackingBodyText>
        <RowFlex>
          <SubHeadingText marginTop={32}>Amount</SubHeadingText>
          <AmountRowContainer>
            <SubHeadingText marginTop={32} color={'lightGrey'}>
              Balance:
            </SubHeadingText>
            {loading ? (
              <LoaderContainer>
                <BarLoader
                  loaderSize={LoaderSize.MEDIUM}
                  color={Theme.colors.background}
                  margin={'0'}
                />
              </LoaderContainer>
            ) : (
              <NumberFormat
                value={availableBalance.toString()}
                displayType={'text'}
                thousandSeparator={true}
                suffix=" STX"
                renderText={(value: string) => (
                  <SubHeadingText marginTop={32} marginLeft={10}>
                    {value}
                  </SubHeadingText>
                )}
              />
            )}
          </AmountRowContainer>
        </RowFlex>
        {renderStxInputField()}
        <CurrencyText marginTop={2}>
          {stxAmountFiatRate ? `~ $ ${stxAmountFiatRate} USD` : `~ $ 0 USD`}
        </CurrencyText>
        {showMinimumAlert && (
          <BodyText color="red" fontSize={12} marginTop={7.8}>
            Amount is lower than the minimum
          </BodyText>
        )}
        {showEmptyStxAlert && (
          <BodyText color="red" fontSize={12} marginTop={7.8}>
            You need to enter a valid STX amount
          </BodyText>
        )}
        {showNotEnoughFundsAlert && (
          <BodyText color="red" fontSize={12} marginTop={7.8}>
            You don't have enough funds
          </BodyText>
        )}
        {renderInfoSection('Be sure to keep enough STX for delegation and redemption fees.')}
        <StackingButton
          disabled={showEmptyStxAlert || showMinimumAlert || showNotEnoughFundsAlert}
          onClick={handleAmountClickNext}
        >
          Next
        </StackingButton>
      </ColumnFlex>
    );
  }

  function renderRewardAddressSection() {
    return (
      <Container>
        <StackingHeadingText marginTop={128}>Enter a BTC address</StackingHeadingText>
        <StackingBodyText marginTop={16} width={496}>
          Enter the BTC address to which you want to receive your reward. Download the Xverse
          extension to get a BTC address.
        </StackingBodyText>
        <SubHeadingText marginTop={40}>BTC reward address</SubHeadingText>
        {renderRewardAddressField()}
        <RowFlex marginTop={138} justifyContent={'flex-start'}>
          <TransparentButton onClick={handleClickBack}>Previous</TransparentButton>
          <StackingButton onClick={handleRecipientClickNext}>Next</StackingButton>
        </RowFlex>
      </Container>
    );
  }

  const allowContractCallerTx = async () => {
    if (allowContractOpacity === '100%') {
      await generateUnsignedAllowContractCallerTransaction(
        address,
        poxContractAddress,
        poxContractName,
        pool?.contract_address,
        pool?.contract,
      );
      setconfirmDelegationOpacity('100%');
      setAllowContractOpacity('50%');
    }
  };
  function renderConfirmDelegationSection() {
    return (
      <RowFlex>
        <ColumnFlex width={500} marginRight={128}>
          <StackingHeadingText marginTop={128}>Confirm your delegation</StackingHeadingText>
          <StackingBodyText marginTop={16}>
            1. Allow the Xverse Pool smart contract to act as your stacking delegate.
          </StackingBodyText>
          <ConfirmDelegationButton opacity={allowContractOpacity} onClick={allowContractCallerTx}>
            Allow contract
          </ConfirmDelegationButton>
          <StackingBodyText marginTop={16}>
            2. Delegate to the Xverse pool smart contract.
          </StackingBodyText>
          <ConfirmDelegationButton opacity={confirmDelegationOpacity} onClick={stackStx}>
            Confirm delegation
          </ConfirmDelegationButton>
          <RowFlex marginTop={40} justifyContent={'flex-start'}>
            <TransparentButton onClick={handleConfirmDelegationClickBack}>
              Previous
            </TransparentButton>
          </RowFlex>
        </ColumnFlex>
        <StackingInfoTile
          lockedAmount={lockedAmount}
          delegatedAmount={isReplacingStackMore ? lockedAmountPlusStxAmount : delegatedAmount}
          stxAmountFiatRate={getStxAmountFiatRate(lockedAmount).toString()}
          poolYield={poolYield}
          cyclesValue={cyclesValue}
          rewardAddress={isStackMore ? userRewardAddress : rewardAddress}
          isPoolClosed={isPoolClosed}
          delegatedToAddress={stackingData?.delegationInfo?.delegatedTo}
          poolAddress={poolRewardAddress}
          stackingState={stackingStatus.stackingState}
          isDelegated={isDelegated}
          hideActions
          registeredForNextCycleState={isReplacingStackMore ? 'in-progress' : undefined}
        />
      </RowFlex>
    );
  }

  function getNextCycleTime() {
    if (poolAvailable && !stackingData?.poolInfo?.open) {
      const blocks = poolAvailable ? stackingData?.poolInfo?.pox?.next_reward_cycle_in! : 0;
      return blocksToTime(blocks);
    } else {
      const blocks = poolAvailable
        ? stackingData?.poolInfo?.pox?.next_reward_cycle_in! -
          stackingData?.poolInfo?.enrollment_closing_blocks!
        : 0;
      return blocksToTime(blocks);
    }
  }

  function renderPoolClosedSection() {
    return (
      <Container>
        <StackingHeadingText marginTop={128} width={497}>
          Pool registration is currently closed.
        </StackingHeadingText>
        <StackingBodyText marginTop={24} width={496}>
          The stacking pool registration will open again in {getNextCycleTime()}. Immediately after
          the start of the next cycle.
        </StackingBodyText>
        <StackingButton
          onClick={() => {
            navigate('/');
          }}
        >
          Back to the homepage
        </StackingButton>
      </Container>
    );
  }

  function renderContent() {
    switch (currentStepIndex) {
      case StartStackingSteps.StepOne:
        return renderLockingStxForNextCycleSection();
      case StartStackingSteps.StepTwo:
        return isStackMore ? renderStackMoreAmountSection() : renderEnterAmountSection();
      case StartStackingSteps.StepThree:
        return renderRewardAddressSection();
      case StartStackingSteps.StepFour:
        return renderConfirmDelegationSection();
    }
  }

  return isPoolClosed ? renderPoolClosedSection() : <ColumnFlex>{renderContent()}</ColumnFlex>;
};
