import { ColumnFlex, RowFlex } from '../../components/styled/Flex';
import StackingProgress from './StackingProgress';
import { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../../Context/LoginState';
import StartStacking from './StartStacking';
import { Ring } from 'react-spinners-css';
import FadeIn from 'react-fade-in';
import useStackingState from '../../hooks/useStackingState';
import { useNavigate } from 'react-router-dom';
import { StackingBodyText, StackingHeadingText } from '../../components/styled/Text';
import { StackingButton } from '../../components/styled/Button';
import StackingInfoTile from './StackingInfoTile';
import { microstacksToStx } from '../../utils/wallet.utils';
import BigNumber from 'bignumber.js';
import { StackingState } from '../../utils/constants';
import { ActionType } from '../../utils/data.type';
import { isDelegating, isDelegatingToXversePool } from '../../utils/delegation';

export const StackingDashboard = () => {
  const navigate = useNavigate();
  const { userData } = useContext(LoginContext);
  const address = userData?.profile.stxAddress.mainnet;
  const {
    stackingStatus,
    initializedData,
    dispatchStackingStatus,
    stackingData,
    stxAddressData,
    refetchStackingData,
  } = useStackingState(address);

  const [isStackMore, setIsStackMore] = useState(false);

  const pool = stackingData?.poolInfo?.pools?.[0];
  const isPoolClosed = !pool || !stackingData?.poolInfo?.open;

  useEffect(() => {
    if (!userData && !initializedData) {
      dispatchStackingStatus({ type: ActionType.RESET_STATE });
      navigate('/');
    }
  }, [userData, navigate, dispatchStackingStatus, initializedData]);

  const renderStackingDashboardContent = (
    <RowFlex justifyContent="center" marginBottom={70}>
      {stackingStatus.stackingState === StackingState.NotStacking ||
      stackingStatus.stackingState === StackingState.Revoked ||
      (isStackMore && stackingStatus.delegatedStxData?.poxBtcAddress) ? (
        <StartStacking
          stackingData={stackingData}
          userRewardAddress={
            isStackMore ? stackingStatus.delegatedStxData?.poxBtcAddress : undefined
          }
          lockedAmount={stackingStatus.delegatedStxData?.lockedAmount}
          delegatedAmount={stackingStatus.delegatedStxData?.delegatedAmount}
          stackingStatus={stackingStatus}
          isDelegated={isDelegating(stackingData)}
          dispatchStackingStatus={dispatchStackingStatus}
          onFinishStackMore={() => {
            refetchStackingData();
            setIsStackMore(false);
          }}
        />
      ) : (
        <StackingProgress
          stackingStatus={stackingStatus}
          isDelegated={isDelegating(stackingData)}
          dispatchStackingStatus={dispatchStackingStatus}
          stackingData={stackingData}
          address={address}
          onStackMore={() => {
            setIsStackMore(true);
          }}
        />
      )}
    </RowFlex>
  );
  const renderDifferentPoolStackingSection = (
    <RowFlex justifyContent="center" marginBottom={70}>
      <ColumnFlex marginRight={100}>
        <StackingHeadingText marginTop={128} width={497}>
          You're already stacking in another pool
        </StackingHeadingText>
        <StackingBodyText marginTop={24} width={496}>
          If you want to earn BTC with Xverse Pool, you need to revoke the current delegation with
          the pool you're using or start stacking with a different address.
        </StackingBodyText>
        <StackingButton
          width={188.5}
          height={48}
          marginTop={54}
          onClick={() => {
            navigate('/');
          }}
        >
          Back to the homepage
        </StackingButton>
      </ColumnFlex>
      <StackingInfoTile
        lockedAmount={microstacksToStx(new BigNumber(stxAddressData?.lockedStackBalance ?? 0))}
        delegatedAmount={BigNumber(0)}
        poolYield={0}
        cyclesValue={0}
        rewardAddress={''}
        isDelegated={isDelegating(stackingData)}
        delegatedToAddress={stackingData?.delegationInfo?.delegatedTo}
        isPoolClosed={isPoolClosed}
      />
    </RowFlex>
  );

  if (!initializedData) {
    return (
      <RowFlex justifyContent="center" marginTop={250} marginBottom={250}>
        <Ring color="white" size={30} />
      </RowFlex>
    );
  }

  if (isDelegating(stackingData) && !isDelegatingToXversePool(stackingData)) {
    return <FadeIn>{renderDifferentPoolStackingSection}</FadeIn>;
  }

  return <FadeIn>{renderStackingDashboardContent}</FadeIn>;
};
