import { createContext } from 'react';
import { UserData } from '@stacks/auth';

export interface LoginState {
  userData: UserData | null;
}

export const defaultState = (): LoginState => {
  return { userData: null };
};

export const LoginContext = createContext<LoginState>(defaultState());
