import React, { FC } from 'react';
import styled from 'styled-components';
import { Theme } from '../../Theme';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  border,
  TypographyProps,
  ColorProps,
  typography,
  color,
} from 'styled-system';

type InputFieldProps = React.HTMLAttributes<HTMLFormElement> &
  LayoutProps &
  SpaceProps &
  TypographyProps &
  ColorProps & {
    children: String | React.ReactNode;
  };

const InputFieldStyle = styled.input<InputFieldProps>`
  border: 1px solid ${Theme.colors.gulfBlue};
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  opacity: 0.4px;
  width: 497px;
  height: 48px;
  outline: none;
  padding: 20px 10px;
  ${space}
  ${layout}
    ${border}
    ${typography}
    ${color}
`;

export const InputField: FC<any> = (props) => {
  return (
    <InputFieldStyle backgroundColor={'charcoal'} color={'white'} style={Theme.body_m} {...props}>
      {props.children}
    </InputFieldStyle>
  );
};
