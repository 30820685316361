import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './Global';
import { Theme } from './Theme';
import { LoginContext } from './Context/LoginState';
import { Connect } from '@stacks/connect-react';
import { useAuth } from './utils/authentication';
import { Header } from './components/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './screens/Landing';
import StackingDashboard from './screens/Stackingdashboard';
import { Footer } from './components/Footer';

function App() {
  const { authOptions, state, handleSignOut } = useAuth();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 900;
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Connect authOptions={authOptions}>
        <LoginContext.Provider value={state}>
          <Router>
            <Header signOut={handleSignOut} isMobile={isMobile} />
            <Routes>
              <Route path="/" element={<Landing isMobile={isMobile} />} />
              <Route path="/stacking" element={<StackingDashboard />} />
            </Routes>
          </Router>
          <Footer />
        </LoginContext.Provider>
      </Connect>
    </ThemeProvider>
  );
}

export default App;
