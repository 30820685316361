import { useCallback, useEffect, useMemo } from 'react';
import { AppConfig, UserSession } from '@stacks/auth';
import { AuthOptions } from '@stacks/connect';
import { defaultState, LoginState } from '../Context/LoginState';
import React from 'react';

export function useAuth() {
  const [state, setState] = React.useState<LoginState>(defaultState());
  const [authResponse, setAuthResponse] = React.useState('');
  const appConfig = useMemo(
    () => new AppConfig(['store_write', 'publish_data'], document.location.href),
    [],
  );
  const userSession = useMemo(() => new UserSession({ appConfig }), [appConfig]);

  const handleSignOut = useCallback(() => {
    userSession.signUserOut();
    setState({ userData: null });
  }, [userSession, setState]);

  const handleRedirectAuth = useCallback(async () => {
    if (userSession.isSignInPending()) {
      const userData = await userSession.handlePendingSignIn();
      setState({ userData });
    }
  }, [userSession]);

  const onFinish = useCallback(({ userSession, authResponse }) => {
    const userData = userSession.loadUserData();
    setAuthResponse(authResponse);
    setState({ userData });
  }, []);

  const onCancel = useCallback(() => {
    console.log('popup closed!');
  }, []);

  useEffect(() => {
    void handleRedirectAuth();
    if (userSession.isUserSignedIn() && !state.userData) {
      const userData = userSession.loadUserData();
      setState({ userData });
    }
  }, [handleRedirectAuth, userSession, state]);

  const authOptions: AuthOptions = {
    appDetails: {
      name: 'Xverse Pool',
      icon: 'https://uploads-ssl.webflow.com/624b08d53d7ac60ccfc11d8d/646358ec6bbfb7a8d93e115c_Icon-Extension-144x144.png',
    },
    redirectTo: '/',
    userSession,
    onFinish,
    onCancel,
  };
  return {
    authOptions,
    state,
    authResponse,
    handleSignOut,
  };
}
