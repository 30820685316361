import { AppDownloadImageButton, RedirectButton } from '../../../components/styled/Button';
import { Container, RowContainer } from '../../../components/styled/Container';
import { RowFlex } from '../../../components/styled/Flex';
import { HeadingText, LandingBodyText } from '../../../components/styled/Text';
import {
  appStoreUrl,
  playStoreUrl,
  xverseExtensionUrl,
  xverseUrl,
} from '../../../utils/url.constants';
import ArrowRight from '../../../assets/images/ArrowRight.svg';
import AppStoreBadge from '../../../assets/images/appstore.svg';
import GoogleChrome from '../../../assets/images/GoogleChrome.svg';
import PlayStoreBadge from '../../../assets/images/playstore.svg';
import XverseMobile from '../../../assets/images/poolvisual.webp';
import styled from 'styled-components';
interface Props {
  isMobile: boolean;
}

const DownloadWalletRowContainer = styled(RowContainer)`
  background-color: #6e54cb;
  border-radius: 16px;
`;

const Image = styled.img<Props>((props) => ({
  width: props.isMobile ? 450 : 560,
}));

export const DownloadWallet = ({ isMobile }: Props) => {
  function appStoreLink() {
    window.open(appStoreUrl, '_blank', 'noopener,noreferrer');
  }
  function extensionLink() {
    window.open(xverseExtensionUrl, '_blank', 'noopener,noreferrer');
  }
  function playStoreLink() {
    window.open(playStoreUrl, '_blank', 'noopener,noreferrer');
  }
  function redirectToXverseApp() {
    window.open(xverseUrl, '_blank', 'noopener,noreferrer');
  }

  return (
    <DownloadWalletRowContainer
      flexDirection={{ s: 'column', md: 'column', lg: 'row' }}
      paddingLeft={{ s: 16, md: 54, lg: 54 }}
      paddingRight={{ s: 16, md: 0, lg: 0 }}
      paddingTop={{ s: 32, md: 0, lg: 0 }}
      marginTop={{ s: 64, md: 64, lg: 280 }}
      marginBottom={{ s: 0, md: 20, lg: 50 }}
      height={{ lg: 375 }}
    >
      <Container justifyContent="flex-start" alignItems="flex-start">
        <HeadingText fontSize={{ s: 21, md: 35, lg: 35 }}>Download the Xverse Wallet</HeadingText>
        <LandingBodyText
          color="grey"
          marginTop={30}
          onClick={() => redirectToXverseApp()}
          fontSize={{ s: 16, md: 18, lg: 18 }}
        >
          Manage your Stacks holdings and earn Bitcoin from your phone. Download Xverse mobile
          wallet for Stacks and Bitcoin.
        </LandingBodyText>
        <RedirectButton
          marginBottom={40}
          fontSize={{ s: 16, md: 18, lg: 18 }}
          onClick={() => redirectToXverseApp()}
        >
          <RowFlex justifyContent={{ s: 'center', md: 'center', lg: 'flex-start' }}>
            Learn more about the Xverse Wallet
            <img
              src={ArrowRight}
              alt="Right Arrow"
              style={{
                marginLeft: 5,
                position: 'relative',
              }}
            />
          </RowFlex>
        </RedirectButton>
        <RowContainer
          justifyContent={'flex-start'}
          width={'100%'}
          flexDirection={{ s: 'column', md: 'row', lg: 'row' }}
        >
          <AppDownloadImageButton
            marginTop={{ s: 10, md: 0, lg: 0 }}
            onClick={() => playStoreLink()}
          >
            <img alt="playstore" src={PlayStoreBadge} height={40} />
          </AppDownloadImageButton>
          <AppDownloadImageButton onClick={() => appStoreLink()}>
            <img alt="app store" src={AppStoreBadge} height={40} />
          </AppDownloadImageButton>
          <AppDownloadImageButton onClick={() => extensionLink()}>
            <img alt="chrome web store" src={GoogleChrome} />
          </AppDownloadImageButton>
        </RowContainer>
      </Container>
      <Image isMobile={isMobile} src={XverseMobile} />
    </DownloadWalletRowContainer>
  );
};
