import { PostCondition } from '@stacks/transactions';
import BigNumber from 'bignumber.js';
import { StackingState, TokenType, TransactionStatus, TransactionType } from './constants';

export type StxAddressDataResponse = {
  balance: string;
  locked: string;
  unlock_height: string;
  nonce: number;
};

export type StxAddressData = {
  availableBalance: BigNumber;
  lockedStackBalance: BigNumber;
};

export type Pool = {
  admin_address: string;
  contract_address: string;
  contract: string;
  contract_version: number;
  reward_address: string;
  starting_cycle: number;
  available_cycle_durations: number[];
  minimum: number;
  fee_percent: number;
  estimated_yield: number;
};

export type Pox = {
  contract_id: string;
  pox_activation_threshold_ustx: number;
  prepare_phase_block_length: number;
  first_burnchain_block_height: number;
  reward_phase_block_length: number;
  min_amount_ustx: number;
  prepare_cycle_length: number;
  rejection_fraction: number;
  reward_cycle_id: number;
  reward_slots: number;
  reward_cycle_length: number;
  rejection_votes_left_required: number;
  total_liquid_supply_ustx: number;
  next_reward_cycle_in: number;
  current_cycle: {
    id: number;
    is_pox_active: boolean;
    stacked_ustx: number;
    min_threshold_ustx: number;
  };
  next_cycle: {
    id: number;
    blocks_until_prepare_phase: number;
    blocks_until_reward_phase: number;
    min_threshold_ustx: number;
    min_increment_ustx: number;
    stacked_ustx: number;
    prepare_phase_start_block_height: number;
    reward_phase_start_block_height: number;
    ustx_until_pox_rejection: number;
  };
};

export type StackingPoolInfo = {
  open: boolean;
  enrollment_closing_blocks: number;
  enrollment_open_blocks: number;
  pools: Array<Pool>;
  pox: Pox;
  pool_total: string;
  burn_block_height: string;
};

export type DelegationInfo = {
  delegated: boolean;
  amount?: string;
  delegatedTo?: string;
  untilBurnHeight?: number;
};

export interface CoreInfo {
  burn_block_height: number;
  stable_burn_block_height: number;
  stacks_tip_height: number;
  stacks_tip: string;
}

export type StackerInfo = {
  stacked: boolean;
  pool_total?: string;
  amount?: string;
  reward_share_pct?: string;
  first_reward_cycle?: number;
  starting_cycle: number;
  lock_period?: number;
  pool_pox_address?: string;
  user_pox_address?: string;
  poolContractName?: string;
};

export type StackingData = {
  poolInfo: StackingPoolInfo | undefined;
  stackerInfo: StackerInfo | undefined;
  coreInfo: CoreInfo | undefined;
  delegationInfo: DelegationInfo | undefined;
};

export type StackingStateData = {
  delegated: string;
  startingCycle: string;
  revoked: string;
  rewardAddress: string;
  poolContractAddress: string;
  amount: string;
  delegationTxid: string;
};

export type CurrentRewardData = {
  currentCyclePoolRewardInBtc: number;
  currentCyclePoolRewardInCurrency: string;
};

export type PoolLockedStx = {
  totalStxLocked: number;
  totalStxLockedInCurrency: number;
};

export type StackingCycleData = {
  currentCycle: number;
  cycleEndsInBlocks: number;
  cycleStartHeightInBlocks: number;
  nextCycleRegistrationClosesInBlocks: number;
  poolStxLocked: PoolLockedStx;
  nextCycleTotalStacked: number;
};

export type PoolBTCAddressInfo = {
  allTimePoolRewardInBTC: number;
  allTimePoolRewardInCurrency: string;
  poolBtcAddressBalance: number;
};

export interface FunctionArg {
  hex: string;
  repr: string;
  name: string;
  type: string;
}

export interface ContractCall {
  contract_id: string;
  function_name: string;
  function_signature: string;
  function_args: FunctionArg[];
}

export type TransactionData = {
  txid: string;
  amount: BigNumber;
  seenTime: Date;
  incoming: boolean;
  txType: TransactionType;
  txStatus: TransactionStatus;
  contractCall?: ContractCall;
  post_conditions: Array<PostCondition>;
  tokenType?: TokenType;
  tokenName?: string;
  recipientAddress?: string;
  memo?: string;
  cycles?: string;
  rewardAddress?: string;
  poolPoxAddress?: string;
  untilBurnHt?: string;
  poolContractAddress?: string;
  poolContractName?: string;
};

export interface StxTransactionData extends TransactionData {
  blockHash: string;
  blockHeight: number;
  burnBlockTime: number;
  burnBlockTimeIso: Date;
  canonical: boolean;
  fee: BigNumber;
  nonce: number;
  postConditionMode: string;
  senderAddress: string;
  tokenTransfer?: {
    recipientAddress: string;
    amount: BigNumber;
    memo: string;
  };
  contractCall?: ContractCall;
  amount: BigNumber;
  sponsored: boolean;
  txid: string;
  txIndex: number;
  txResults: string;
  txStatus: TransactionStatus;
  txType: TransactionType;
  seenTime: Date;
}

export type StxTransactionListData = {
  transactionsList: Array<StxTransactionData>;
  totalCount: number;
};

export interface StxTransactionDataResponse {
  block_hash: string;
  block_height: number;
  burn_block_time: number;
  burn_block_time_iso: string;
  canonical: boolean;
  fee_rate: string;
  nonce: number;
  post_condition_mode: string;
  sender_address: string;
  token_transfer: {
    recipient_address: string;
    amount: string;
    memo: string;
  };
  sponsored: boolean;
  tx_id: string;
  tx_index: number;
  tx_results: string;
  tx_status: TransactionStatus;
  tx_type: TransactionType;
  contract_call?: ContractCall;
  events?: Array<Event>;
  post_conditions?: PostCondition[];
}

export interface StxTransactionResponse {
  limit: number;
  offset: number;
  total: number;
  results: Array<StxTransactionDataResponse>;
}

export interface StxTransactionStatusResponse {
  tx_id: string;
  tx_status: string;
  tx_result: {
    hex: string;
    repr: string;
  };
}

export type delegateStxContractCallArg = {
  amount: string;
  delegate_to: string;
  user_pox_address: string;
  pool_pox_address: string;
  until_burn_ht: string;
  lock_period: string;
};

export interface RawTransactionResponse {
  raw_tx: string;
}

export type DelegatedStxData = {
  delegatedAmount: BigNumber;
  delegatedToAddress: string;
  lockedAmount: BigNumber;
  poxBtcAddress: string;
  hasPendingDelegate: boolean;
};

export enum ActionType {
  SET_DELEGATING = 'SET_DELEGATING',
  SET_NOT_STACKING = 'SET_NOT_STACKING',
  SET_STACKING_STATUS = 'SET_STACKING_STATUS',
  RESET_STATE = 'RESET_STATE',
}

export type Action = {
  type: ActionType;
  payload?: StackingStatus;
};

export type StackingStatus = {
  stackingState: StackingState;
  delegatedStxData: DelegatedStxData | null;
};

export type StxMempoolResponse = {
  limit: number;
  offset: number;
  total: number;
  results: Array<StxMempoolTransactionDataResponse>;
};

export type AssetPostCondition = {
  contract_name: string;
  asset_name: string;
  contract_address: string;
};

export type TransactionPostCondition = {
  type: TokenType;
  condition_code: string;
  amount: string;
  principal: {
    type_id: string;
    address: string;
  };
  asset: AssetPostCondition;
  asset_value?: { hex: string; repr: string };
};

export type StxMempoolTransactionDataResponse = {
  receipt_time: number;
  receipt_time_iso: string;
  fee_rate: string;
  nonce: number;
  post_condition_mode: string;
  sender_address: string;
  token_transfer: {
    recipient_address: string;
    amount: string;
    memo: string;
  };
  sponsored: boolean;
  tx_id: string;
  tx_status: TransactionStatus;
  tx_type: TransactionType;
  contract_call?: ContractCall;
  post_conditions?: TransactionPostCondition[];
};

export interface StxMempoolTransactionData extends TransactionData {
  receiptTime: number;
  receiptTimeIso: Date;
  fee: BigNumber;
  nonce: number;
  postConditionMode: string;
  senderAddress: string;
  tokenTransfer?: {
    recipientAddress: string;
    amount: BigNumber;
    memo: string;
  };
  sponsored: boolean;
  txid: string;
  txStatus: TransactionStatus;
  txType: TransactionType;
  seenTime: Date;
}

export type StxMempoolTransactionListData = {
  transactionsList: Array<StxMempoolTransactionData>;
  totalCount: number;
};

export type DelegateInfo = {
  amount: string;
  delegateTo: string;
  userPoxAddress: string;
};

export type Rates = { stxBtcRate: BigNumber; btcFiatRate: BigNumber };
