import styled from 'styled-components';
import { Theme } from '../../Theme';
import { Container } from './Container';

interface Props {
  width: string;
}

const Bar = styled.div<Props>`
  progress {
    position: absolute;
    margin-top: 0px;
    margin-right: 16px;
  }

  progress[value] {
    -webkit-appearance: none;
    appearance: none;
    width: ${(props) => props.width};
    height: 6px;
  }

  progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: ${Theme.colors.arsenic};
  }

  progress[value]::-webkit-progress-value {
    border-radius: 4px;
    background-color: ${Theme.colors.white};
  }
`;

const StackingCompleteBar = styled.div`
  position: absolute;
  top: 25%;
  progress {
    position: absolute;
    margin-top: 0px;
    margin-right: 16px;
  }

  progress[value] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 6px;
  }

  progress[value]::-webkit-progress-value {
    border-radius: 4px;
    background-color: ${Theme.colors.green};
  }
`;

export const ContinuousProgressBar = ({ value, width }) => {
  return (
    <Container>
      <Bar width={width}>
        <progress value={value} max={100} />
      </Bar>
    </Container>
  );
};

export const CompletedContinuousProgressBar = ({ value, width }) => {
  return (
    <Container>
      <StackingCompleteBar>
        <progress value={value} max={100} />
      </StackingCompleteBar>
    </Container>
  );
};
