import styled from 'styled-components';
import { ColumnFlex } from '../../../components/styled/Flex';

const TitleText = styled.h1((props) => ({
  ...props.theme.body_medium_l,
  color: props.theme.colors.grey,
  marginTop: 33,
}));

const ValueText = styled.h1((props) => ({
  ...props.theme.headline_s,
  color: props.color ?? props.theme.colors.white,
}));

interface Props {
  title: string;
  value: string | number;
  color?: string;
}

export const InfoTile = ({ title, value, color }: Props) => {
  return (
    <ColumnFlex>
      <TitleText>{title}</TitleText>
      <ValueText color={color}>{value}</ValueText>
    </ColumnFlex>
  );
};
