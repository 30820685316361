import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchPoolParticipants, fetchStackingPoolInfo } from '../../../utils/api';
import { StackingData, StackingPoolInfo } from '../../../utils/data.type';
import { InfoTile } from './InfoTile';

const LeftContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 82,
  alignItems: 'space-between',
});

const CardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 24,
  paddingRight: 24,
  paddingBottom: 36,
  width: 408,
  height: 223,
  background: 'linear-gradient(42.24deg, #1d2032 0%, rgba(29, 32, 50, 0) 100%)',
  border: '1px solid #272a44',
  borderRadius: 8,
  '@media (max-width: 900px)': {
    width: 343,
  },
});

const RightContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
});

const RowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'space-between',
});
interface Props {
  stackingData: StackingData;
}

export const CycleInfoCard = ({ stackingData }: Props) => {
  const [estimatedYield, setEstimatedYield] = useState<number>(0);
  const [poolParticipants, setPoolParticipants] = useState<number>(0);
  const [currentCycle, setCurrentCycle] = useState<number>(0);

  useEffect(() => {
    const fetchInfo = async () => {
      const participants = await fetchPoolParticipants();
      if (participants) setPoolParticipants(participants);
    };
    fetchInfo();
  }, []);

  useEffect(() => {
    const fetchInfo = async () => {
      let poolInfo: StackingPoolInfo;
      stackingData?.poolInfo
        ? (poolInfo = stackingData?.poolInfo)
        : (poolInfo = await fetchStackingPoolInfo());
      setCurrentCycle(poolInfo?.pox?.current_cycle?.id);
      if (poolInfo && poolInfo.pools.length > 0) {
        const pools = poolInfo.pools[0];
        setEstimatedYield(pools.estimated_yield);
      }
    };
    fetchInfo();
  }, [stackingData]);

  return (
    <CardContainer>
      <RowContainer>
        <LeftContainer>
          <InfoTile value={currentCycle} title="Current Cycle" />
          <InfoTile value={poolParticipants} title="Stackers" />
        </LeftContainer>

        <RightContainer>
          <InfoTile value={`${estimatedYield ?? '~10'}%`} title="Average Annual Yield" />
          <InfoTile value="None" title="Pool Fees" color="#51D6A6" />
        </RightContainer>
      </RowContainer>
    </CardContainer>
  );
};
