export const xverseApiBaseUrl = process.env.REACT_APP_XVERSE_API;
export const hiroMainnetBaseUrl = 'https://api.mainnet.hiro.so';
export const appStoreUrl = 'https://apps.apple.com/us/app/xverse/id1552272513';
export const playStoreUrl =
  'https://play.google.com/store/apps/details?id=com.secretkeylabs.xverse';
export const xverseDiscordUrl = 'https://discord.gg/xverse';
export const xverseTwitterUrl = 'https://twitter.com/xverseApp';
export const xverseFAQUrl = 'https://www.xverse.app/faq';
export const xverseSupportUrl = 'https://support.xverse.app/hc/en-us';
export const xverseUrl = 'https://www.xverse.app/';
export const stackUrl = 'https://www.stacks.co/learn/stacking';
export const xverseBlog =
  ' https://www.xverse.app/blog/earn-bitcoin-by-stacking-with-xverse-wallet';
export const xverseExtensionUrl =
  'https://chrome.google.com/webstore/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg';
