import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { fetchPoolBtcAddressInfo } from '../utils/api';
import { satsToBtc } from '../utils/wallet.utils';

export default function useCycleBtcReward() {
  const [totalReward, setTotalReward] = useState<number>(0);

  useEffect(() => {
    const fetchInfo = async () => {
      const totalReward = await fetchPoolBtcAddressInfo();
      const btcRewardThisCycle = satsToBtc(new BigNumber(totalReward.poolBtcAddressBalance));
      if (btcRewardThisCycle) setTotalReward(Number(btcRewardThisCycle.toFixed(5)));
    };
    fetchInfo();
  }, []);

  return {
    totalReward,
  };
}
