import React, { FC } from 'react';
import styled from 'styled-components';

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  shadow,
  ShadowProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';

export type ConStyleProps = BorderProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  ShadowProps;

export type ConProps = ConStyleProps &
  React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
  };

export const ContainerStyle = styled.div<ConStyleProps>`
  display: flex;
  ${space}
  ${shadow}
    ${color}
    ${layout}
    ${flexbox}
    ${border}
`;

export const ProgressBarContainerStyle = styled.div<ConStyleProps>`
  display: flex;
  position: relative;
  ${space}
  ${shadow}
    ${color}
    ${layout}
    ${flexbox}
    ${border}
`;

export const StackingContainerStyle = styled.div<ConStyleProps>`
  display: flex;
  background: linear-gradient(42.24deg, #1d2032 0%, rgba(29, 32, 50, 0) 100%);
  border: 1px solid #272a44;
  border-radius: 8px;
  ${space}
  ${shadow}
    ${color}
    ${layout}
    ${flexbox}
    ${border}
`;

export const Container: FC<ConProps> = (props) => {
  return (
    <ContainerStyle display="flex" flexDirection="column" justifyContent="center" {...props}>
      {props.children}
    </ContainerStyle>
  );
};
export const StyledContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      display="flex"
      flexDirection="column"
      justifyContent="center"
      bg="transparent"
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};

export const RowContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      alignItems="center"
      justifyContent={{ s: 'center', md: 'center', lg: 'space-between' }}
      bg="transparent"
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};
export const DashboardContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      flexDirection="column"
      backgroundColor="blackRock"
      marginTop={22.8}
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
      borderRadius={8}
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};

export const StackingDashboardContainer: FC<ConProps> = (props) => {
  return (
    <StackingContainerStyle
      flexDirection="column"
      paddingLeft={24}
      paddingRight={24}
      paddingBottom={36}
      {...props}
    >
      {props.children}
    </StackingContainerStyle>
  );
};
export const HeaderContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      flexDirection="row-reverse"
      alignItems="center"
      paddingY={26.45}
      marginX="auto"
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};

export const FooterContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      flexDirection="column"
      alignItems="center"
      paddingBottom={20}
      marginTop="10%"
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};

export const DashboardHorizontalContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      color="charcoal"
      width={524}
      height={48}
      borderRadius={8}
      border="1px solid #353E55"
      alignItems="center"
      flex-direction="row"
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};

export const OutlinedStepperContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      backgroundColor="transparent"
      width={32}
      height={32}
      borderRadius={20}
      border="2px solid #5546FF"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};
export const FilledStepperContainer: FC<ConProps> = (props) => {
  return (
    <ContainerStyle
      backgroundColor="purple"
      width={32}
      height={32}
      borderRadius={20}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {props.children}
    </ContainerStyle>
  );
};

export const SegmentContainer: FC<ConProps> = (props) => {
  return (
    <ProgressBarContainerStyle
      marginTop={3}
      flexDirection="row"
      justifyContent="space-between"
      width={553}
      {...props}
    >
      {props.children}
    </ProgressBarContainerStyle>
  );
};
