import BigNumber from 'bignumber.js';
import moment from 'moment';
import * as bitcoin from 'bitcoinjs-lib';
import {
  fetchBtcToCurrencyRate,
  fetchStxToBtcRate,
  getConfirmedTransactions,
  getMempoolTransactions,
} from './api';
import {
  StackingStateData,
  StackingData,
  StxTransactionData,
  StxTransactionDataResponse,
  StxMempoolTransactionDataResponse,
  StxMempoolTransactionData,
  DelegationInfo,
  StackerInfo,
  DelegateInfo,
  Rates,
  StackingPoolInfo,
} from './data.type';
import {
  DELEGATE_CONTRACT_ADDRESS,
  DELEGATE_CONTRACT_NAME,
  DELEGATE_STX_FUNCTION_NAME,
  POX_CONTRACT_ADDRESS,
  POX_CONTRACT_NAME,
  REVOKE_DELEGATION_FUNCTION_NAME,
  StackingState,
  TransactionType,
} from './constants';
import { extractPoxAddressFromClarityValue, poxAddressToBtcAddress } from '@stacks/stacking';
import { TupleCV, hexToCV } from '@stacks/transactions';

export const satsToBtc = (sats: BigNumber) => sats.multipliedBy(0.00000001);

export const microstacksToStx = (microstacks: BigNumber | undefined) =>
  microstacks?.multipliedBy(0.000001);

export const stxToMicrostacks = (stacks: BigNumber) => stacks.multipliedBy(1000000);

export const getBtcFiatEquivalent = async (btcAmount: BigNumber) => {
  const btcFiatRate = await fetchBtcToCurrencyRate();
  return btcAmount.multipliedBy(btcFiatRate);
};

export const getStacksRates = async (): Promise<Rates> => {
  const [stxBtcRate, btcFiatRate] = await Promise.all([
    fetchStxToBtcRate(),
    fetchBtcToCurrencyRate(),
  ]);
  return { stxBtcRate, btcFiatRate };
};

export const blocksToWeeks = (blocks: number): string => {
  const minutes = blocks * 10;
  const duration = moment.duration(minutes, 'm');
  return duration.humanize({ d: 7, w: 100 });
};

export const blocksToTime = (blocks: number): string => {
  const minutes = blocks * 10;
  const duration = moment.duration(minutes, 'm');
  return duration.humanize();
};

export const getTimeOfCycleEnd = (poolInfo?: StackingPoolInfo) => {
  const poolAvailable = poolInfo?.pools?.length > 0;
  const cycleEndBlocks = poolAvailable ? poolInfo?.pox?.next_reward_cycle_in! : 0;
  return blocksToTime(cycleEndBlocks);
};

export const getStackerCyclesValue = ({
  poolInfo,
  stackerInfo,
}: {
  poolInfo?: StackingPoolInfo;
  stackerInfo?: StackerInfo;
}) => {
  if (!stackerInfo?.stacked) {
    return 0;
  }

  const currentCycle = poolInfo?.pox.reward_cycle_id;
  const startingCycle = stackerInfo?.first_reward_cycle;
  if (currentCycle - startingCycle > 0) {
    return currentCycle - startingCycle;
  }

  return 0;
};

export function roundBtc(btc: BigNumber): string {
  return satsToBtc(btc).toNumber().toFixed(8);
}

export function validateBtcAddress(btcAddress: string): boolean {
  const btcNetwork = bitcoin.networks.bitcoin;
  try {
    bitcoin.address.toOutputScript(btcAddress, btcNetwork);
    return true;
  } catch (error) {
    return false;
  }
}

export function isBech32BtcAddress(btcAddress: string): boolean {
  try {
    bitcoin.address.fromBech32(btcAddress);
    return true;
  } catch (error) {
    return false;
  }
}

export const getStackingDisplayState = ({
  stackingData,
  hasPendingDelegate,
  hasPendingRevoke,
}: {
  stackingData: StackingData | null;
  hasPendingDelegate: boolean;
  hasPendingRevoke: boolean;
}): StackingState => {
  const { stackerInfo, delegationInfo } = stackingData || {};

  if (stackerInfo?.starting_cycle && stackerInfo.starting_cycle <= 59) {
    return StackingState.NotStacking;
  }

  const isStacking = stackerInfo?.stacked;
  const isDelegated = delegationInfo?.delegated;

  if (hasPendingRevoke) {
    return StackingState.Revoked;
  }

  // after revoke with stx locked, user "is stacking" till the end of the cycle but is not delegated. so for the stacking state we need to check if user is delegated as well
  if (isStacking && isDelegated) {
    return StackingState.Stacking;
  }

  if (isDelegated) {
    return StackingState.Delegated;
  }

  if (hasPendingDelegate) {
    return StackingState.Pending;
  }

  return StackingState.NotStacking;
};

export function parseStxTransactionData(
  responseTx: StxTransactionDataResponse,
  stxAddress: string,
): StxTransactionData {
  const parsedTx: StxTransactionData = {
    blockHash: responseTx.block_hash,
    blockHeight: responseTx.block_height,
    burnBlockTime: responseTx.burn_block_time,
    burnBlockTimeIso: new Date(responseTx.burn_block_time_iso),
    canonical: responseTx.canonical,
    fee: new BigNumber(responseTx.fee_rate),
    nonce: responseTx.nonce,
    postConditionMode: responseTx.post_condition_mode,
    senderAddress: responseTx.sender_address,
    sponsored: responseTx.sponsored,
    txid: responseTx.tx_id,
    txIndex: responseTx.tx_index,
    txResults: responseTx.tx_results,
    txStatus: responseTx.tx_status,
    txType: responseTx.tx_type,
    seenTime: new Date(responseTx.burn_block_time_iso),
    incoming: responseTx.sender_address !== stxAddress,
    amount: new BigNumber(0),
    post_conditions: [],
  };
  if (parsedTx.txType === TransactionType.ContractCall) {
    parsedTx.contractCall = responseTx.contract_call;
  }
  return parsedTx;
}

export function parseMempoolStxTransactionsData(
  responseTx: StxMempoolTransactionDataResponse,
  stxAddress: string,
): StxMempoolTransactionData {
  const parsedTx: StxMempoolTransactionData = {
    receiptTime: responseTx.receipt_time,
    receiptTimeIso: new Date(responseTx.receipt_time_iso),
    fee: new BigNumber(responseTx.fee_rate),
    nonce: responseTx.nonce,
    postConditionMode: responseTx.post_condition_mode,
    senderAddress: responseTx.sender_address,
    sponsored: responseTx.sponsored,
    txid: responseTx.tx_id,
    txStatus: responseTx.tx_status,
    txType: responseTx.tx_type,
    seenTime: new Date(responseTx.receipt_time_iso),
    incoming: responseTx.sender_address !== stxAddress,
    amount: new BigNumber(responseTx?.token_transfer?.amount ?? 0),
    post_conditions: [],
  };

  if (parsedTx.txType === 'contract_call') {
    parsedTx.contractCall = responseTx.contract_call;
  }
  return parsedTx;
}

export function checkStackingForCurrentCycle(
  stackingData: StackingData | undefined,
  stackingState: StackingStateData | undefined,
) {
  const currentCycle = stackingData?.poolInfo?.pox.reward_cycle_id!;
  const startingCycle = Number(stackingState?.startingCycle);
  if (currentCycle && currentCycle < startingCycle) {
    return true;
  } else {
    return false;
  }
}

export function getUniquePendingTx({
  confirmedTransactions,
  pendingTransactions,
}: {
  confirmedTransactions: StxTransactionData[];
  pendingTransactions: StxMempoolTransactionData[];
}): StxMempoolTransactionData[] {
  if (!pendingTransactions.length) {
    return pendingTransactions;
  }
  return [
    ...new Map(
      pendingTransactions
        .filter((pendingTx) => pendingTx.incoming !== true)
        .filter(
          (pendingTx) =>
            !confirmedTransactions.find((confirmedTx) => confirmedTx.txid === pendingTx.txid),
        )
        .map((m) => [m.txid, m]),
    ).values(),
  ];
}

export const fetchStxTransactions = async (stxAddress: string) => {
  const [confirmedTransactions, mempoolTransactions] = await Promise.all([
    getConfirmedTransactions(stxAddress),
    getMempoolTransactions(stxAddress),
  ]);

  const pendingTransactions = getUniquePendingTx({
    confirmedTransactions: confirmedTransactions.transactionsList,
    pendingTransactions: mempoolTransactions.transactionsList,
  });

  return {
    confirmedTransactions: confirmedTransactions.transactionsList,
    pendingTransactions,
  };
};

export const getPendingRevokeOrDelegateTxList = (
  pendingTransactions?: StxMempoolTransactionData[],
) =>
  pendingTransactions?.filter((tx) => {
    const isPending = tx.txStatus === 'pending';
    const isContractCall = tx.txType === 'contract_call';

    const isStackingContractCall =
      tx.contractCall?.contract_id === `${POX_CONTRACT_ADDRESS}.${POX_CONTRACT_NAME}` ||
      tx.contractCall?.contract_id === `${DELEGATE_CONTRACT_ADDRESS}.${DELEGATE_CONTRACT_NAME}`;

    const isFunctionNameCorrect =
      tx.contractCall?.function_name === DELEGATE_STX_FUNCTION_NAME ||
      tx.contractCall?.function_name === REVOKE_DELEGATION_FUNCTION_NAME;

    return isPending && isContractCall && isStackingContractCall && isFunctionNameCorrect;
  }) ?? [];

export const getPendingRevokeOrDelegateState = (
  pendingRevokeOrDelegateTxList: StxMempoolTransactionData[],
) => {
  // we use pending tx to fill the gaps between pending delegation and pending revoke
  let hasPendingRevoke = false;
  let hasPendingDelegate = false;
  pendingRevokeOrDelegateTxList.forEach((tx) => {
    if (tx.contractCall?.function_name === DELEGATE_STX_FUNCTION_NAME) {
      hasPendingDelegate = true;
      return;
    }
    if (tx.contractCall?.function_name === REVOKE_DELEGATION_FUNCTION_NAME) {
      hasPendingRevoke = true;
      return;
    }
  });

  return { hasPendingRevoke, hasPendingDelegate };
};

const getLastDelegateTx = (
  txList?: (StxTransactionData | StxMempoolTransactionData)[],
): StxTransactionData | StxMempoolTransactionData | undefined =>
  txList?.find(
    (tx) =>
      tx.contractCall?.contract_id === `${DELEGATE_CONTRACT_ADDRESS}.${DELEGATE_CONTRACT_NAME}` &&
      tx.contractCall?.function_name === DELEGATE_STX_FUNCTION_NAME,
  );

const getUserPoxAddressFromHex = (hex: string) => {
  const bufferedUserBtcAddress = hexToCV(hex);
  const userAddress = bufferedUserBtcAddress as TupleCV;
  const { version, hashBytes } = extractPoxAddressFromClarityValue(userAddress);
  return poxAddressToBtcAddress(version, hashBytes, 'mainnet');
};

export const getDelegateInfo = ({
  delegationInfo,
  confirmedTxList,
}: {
  delegationInfo: DelegationInfo;
  confirmedTxList: StxTransactionData[];
}): DelegateInfo => {
  const lastDelegateTx = getLastDelegateTx(confirmedTxList);

  const amount = delegationInfo?.delegated && delegationInfo.amount ? delegationInfo.amount : '';
  const delegateTo =
    delegationInfo?.delegated && delegationInfo.delegatedTo ? delegationInfo.delegatedTo : '';

  // when user is not stacking yet but is delegated, we grab the user pox address from the last delegate tx
  const userPoxAddressHex = lastDelegateTx?.contractCall?.function_args.find(
    (arg) => arg.name === 'user-pox-addr',
  )?.hex;
  const userPoxAddress = userPoxAddressHex ? getUserPoxAddressFromHex(userPoxAddressHex) : '';

  return {
    amount,
    delegateTo,
    userPoxAddress,
  };
};

export const getPendingDelegateInfo = (
  pendingRevokeOrDelegateTxList: StxMempoolTransactionData[],
): DelegateInfo => {
  const lastPendingDelegateTx = getLastDelegateTx(pendingRevokeOrDelegateTxList);

  let amount = '',
    delegateTo = '',
    userPoxAddress = '';

  lastPendingDelegateTx?.contractCall?.function_args.forEach((arg) => {
    if (arg.name === 'delegate-to') {
      delegateTo = arg.repr.split("'").join('') as string;
      return;
    }
    if (arg.name === 'amount-ustx') {
      const value = arg.repr.split('u').join('');
      amount = value;
      return;
    }
    if (arg.name === 'user-pox-addr') {
      userPoxAddress = getUserPoxAddressFromHex(arg.hex);
    }
  });

  return {
    amount,
    delegateTo,
    userPoxAddress,
  };
};
