import styled from 'styled-components';
import { HeadingText } from '../../../components/styled/Text';
import { PerkCard } from './PerkCard';
import Tag from '../../../assets/images/Tag.svg';
import Lock from '../../../assets/images/Lock.svg';
import Key from '../../../assets/images/Key.svg';

interface Props {
  isMobile?: boolean;
}
const GridContainer = styled.div<Props>((props) => ({
  display: 'grid',
  columnGap: 15,
  rowGap: 30,
  gridTemplateColumns: props.isMobile ? '350px' : 'auto auto auto auto',
}));

export const PoolPerks = ({ isMobile }) => {
  const XversePoolPerks = [
    {
      image: Tag,
      title: 'No fees',
      description:
        'You receive the full Bitcoin reward from the protocol level. Xverse pool does not charge a fee.',
    },
    {
      image: Key,
      title: 'Self-custodial',
      description:
        'You do not transfer ownership of any assets to the pool. Using delegation, your stacked tokens remain in your wallet for the entire stacking duration.',
    },
    {
      image: Lock,
      title: 'Secure & Transparent',
      description:
        'Xverse pool is smart contract based meaning all pool operations are on-chain with full transparency.',
    },
  ];
  return (
    <>
      <HeadingText
        marginTop={{ s: 64, md: 158, lg: 105 }}
        marginBottom={{ s: 32, md: 32, lg: 50 }}
        fontSize={{ s: 21, md: 21, lg: 35 }}
        textAlign={{ s: 'start', lg: 'center' }}
      >
        The perks of Xverse Pool
      </HeadingText>
      <GridContainer isMobile={isMobile}>
        {XversePoolPerks.map((perk) => (
          <PerkCard title={perk.title} description={perk.description} image={perk.image} />
        ))}
      </GridContainer>
    </>
  );
};
