import styled from 'styled-components';
import { PrimaryButton, TransparentSquareButton } from '../../../../components/styled/Button';
import { InputField } from '../../../../components/styled/InputField';

export const AlertContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 16,
  marginTop: 24,
  marginBottom: 54,
  borderRadius: 12,
  border: '1px solid rgba(255, 255, 255, 0.2)',
});

export const StackingButton = styled(PrimaryButton)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: 188.5,
  marginTop: 48,
}));

export const TransparentButton = styled(TransparentSquareButton)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: 188.5,
  marginRight: 16,
  marginTop: 48,
}));

export const AmountInputFieldRow = styled(InputField)`
  border: none;
  height: 40px;
  width: 100%;
`;

interface StackingButtonProps {
  isError?: boolean;
}
export const AddressInputField = styled(InputField)<StackingButtonProps>((props) => ({
  type: 'text',
  width: 600,
  marginTop: 10.2,
  border: `1px solid ${
    props.isError ? props.theme.colors.dullRed : props.theme.colors.bg.elevation3
  }`,
}));

export const TickerText = styled.h1((props) => ({
  ...props.theme.body_medium_m,
  color: props.theme.colors.white,
}));

interface InputFieldContainerProps {
  isError?: boolean;
}
export const InputFieldContainer = styled.div<InputFieldContainerProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: props.theme.colors.charcoal,
  height: 48,
  padding: '12px 16px',
  gap: 16,
  borderRadius: 8,
  background: props.theme.colors.bg['elevation-1'],
  border: `1px solid ${
    props.isError ? props.theme.colors.dullRed : props.theme.colors.bg.elevation3
  }`,
  marginTop: 12,
}));

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 600,
  alignSelf: 'center',
});

export const AmountRowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-end',
});

export const LoaderContainer = styled.div({
  width: 50,
  marginTop: 32,
  height: 20,
  marginLeft: 10,
});
