import { POOL_ADMIN_ADDRESS } from '../utils/constants';
import { StackingData } from '../utils/data.type';

export function isDelegating(stackingStatus: StackingData) {
  return stackingStatus?.delegationInfo.delegated;
}

export function isDelegatingToXversePool(stackingData: StackingData) {
  return (
    stackingData?.delegationInfo?.delegated &&
    stackingData?.delegationInfo?.delegatedTo === POOL_ADMIN_ADDRESS
  );
}

export function isStacking(stackingData: StackingData) {
  return stackingData.stackerInfo.stacked;
}
