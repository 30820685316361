export const Theme = {
  colors: {
    white: '#FFFFFF',
    shadedWhite: 'rgba(255, 255, 255, 0.7)',
    purple: '#5546FF',
    background: '#161A24',
    grey: 'rgba(255, 255, 255, 0.8)',
    lightGrey: 'rgba(255, 255, 255, 0.6)',
    charcoal: '#070A13',
    blackRock: '#262B37',
    black: '#12151E',
    gulfBlue: '#353E55',
    licorice: '#323743',
    red: '#D33C3C',
    yellow: '#F2A900',
    arsenic: '#333848',
    dullRed: 'rgba(211, 60, 60, 0.4)',
    green: '#51D6A6',
    cornFlowerBlue: '#24252C',
    perano90: 'rgba(168, 173, 221, 0.9)',
    dimBackground: 'rgba(16, 19, 32, 0.7)',
    action: '#FFFFFF',
    mediumSlateBlue: '#6A79EA',
    lightPurple: '#7383FF',
    grandis: '#ffd28a',
    orange: '#EE7A30',
    bg: {
      'elevation-1': '#070A13',
      elevation0: '#12151E',
      elevation1: '#1D2032',
      elevation2: '#272A44',
      elevation3: '#303354',
      elevation6: '#4C5187',
      elevation8: '#7E89AB',
      elevation9: 'rgba(76, 81, 135, 0.2)',
      elevation10: 'rgba(76, 81, 135, 0.35)',
      modalBackdrop: 'rgba(18,21,30,0.6)',
    },
  },
  headline_category_m: {
    fontFamily: 'DMSans-Medium',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    letterSpacing: 0.02,
    textTransform: 'uppercase',
  },
  headline_category_s: {
    fontFamily: 'DMSans-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 11,
    letterSpacing: 0.02,
  },
  body_bold_l: {
    fontFamily: 'DMSans-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
  },
  body_medium_l: {
    fontFamily: 'DMSans-Regular',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
  },
  body_l: {
    fontFamily: 'DMSans-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
  },
  body_bold_m: {
    fontFamily: 'DMSans-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 14,
  },
  body_medium_m: {
    fontFamily: 'DMSans-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
  },
  body_m: {
    fontFamily: 'DMSans-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
  },
  body_xs: {
    fontFamily: 'DMSans-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
  },
  headline_xl: {
    fontFamily: 'IBMPlexSans-Regular',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 42,
    letterSpacing: 0.02,
  },
  tile_text: {
    fontFamily: 'Satoshi-Regular',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 18,
    letterSpacing: 0.02,
  },
  headline_l: {
    fontFamily: 'IBMPlexSans-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 34,
    letterSpacing: 0.02,
  },
  headline_m: {
    fontFamily: 'IBMPlexSans-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    letterSpacing: 0.02,
  },
  headline_s: {
    fontFamily: 'IBMPlexSans-Medium',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 21,
    letterSpacing: 0.02,
  },
  breakpoints: {
    s: '0px',
    md: '700px',
    lg: '1025px',
    xlg: '1536px',
  },
};
