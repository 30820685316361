import { useContext, useEffect, useState } from 'react';
import { StxAddressData } from '../utils/data.type';
import { fetchStxAddressData } from '../utils/api';
import { LoginContext } from '../Context/LoginState';
import BigNumber from 'bignumber.js';
import { microstacksToStx } from '../utils/wallet.utils';

export default function useUserBalance() {
  const { userData } = useContext(LoginContext);
  const address = userData?.profile.stxAddress.mainnet;
  const [loading, setLoadingData] = useState<boolean>(false);
  const [availableBalance, setAvailableBalance] = useState(new BigNumber(0));
  const [lockedAmount, setLockedAmount] = useState(new BigNumber(0));

  useEffect(() => {
    const fetchInfo = async () => {
      if (address) {
        setLoadingData(true);
        const stxBalance: StxAddressData = await fetchStxAddressData(address);
        setAvailableBalance(microstacksToStx(stxBalance?.availableBalance));
        setLockedAmount(microstacksToStx(stxBalance?.lockedStackBalance));
        setLoadingData(false);
      }
    };
    fetchInfo();
  }, [address]);

  return {
    availableBalance,
    lockedAmount,
    loading,
  };
}
