import ContentLoader from 'react-content-loader';
import { LoaderSize } from '../../utils/constants';

interface Props {
  loaderSize?: LoaderSize;
  color: string;
  margin: string;
}
const BarLoader: React.FC<Props> = ({ loaderSize, color, margin }) => {
  function getHeight() {
    switch (loaderSize) {
      case LoaderSize.SMALLEST:
        return 10;
      case LoaderSize.SMALL:
        return 15;
      case LoaderSize.MEDIUM:
        return 25;
      case LoaderSize.LARGE:
        return 30;
      default:
        return 15;
    }
  }

  function getWidth() {
    switch (loaderSize) {
      case LoaderSize.SMALLEST:
        return 120;
      case LoaderSize.SMALL:
        return 150;
      case LoaderSize.MEDIUM:
        return 250;
      case LoaderSize.LARGE:
        return 300;
      default:
        return 100;
    }
  }

  return (
    <ContentLoader
      animate={true}
      speed={1}
      interval={0.1}
      viewBox="0 0 380 40"
      backgroundColor={color}
      foregroundColor={'#999'}
    >
      <rect y="8" x={margin} rx="4" ry="4" width={getWidth()} height={getHeight()} />
    </ContentLoader>
  );
};

export default BarLoader;
