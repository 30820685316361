import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  StackingData,
  StackingStatus,
  StackingPoolInfo,
  CoreInfo,
  StxTransactionData,
  StxMempoolTransactionData,
  StxAddressData,
  ActionType,
} from '../utils/data.type';
import {
  fetchCoreInfo,
  fetchStackingPoolInfo,
  fetchStackingStatus,
  fetchStxAddressData,
} from '../utils/api';
import { fetchDelegationState } from '../utils/transaction';
import {
  fetchStxTransactions,
  getDelegateInfo,
  getPendingDelegateInfo,
  getStackingDisplayState,
  getPendingRevokeOrDelegateTxList,
  microstacksToStx,
  getPendingRevokeOrDelegateState,
} from '../utils/wallet.utils';
import { StackingState } from '../utils/constants';
import reducer from '../reducer';
import BigNumber from 'bignumber.js';

const initialState: StackingStatus = {
  stackingState: StackingState.NotStacking,
  delegatedStxData: null,
};

export default function useStackingState(address: string) {
  const [stackingData, setStackingData] = useState<StackingData>(undefined);
  const [stxAddressData, setStxAddressData] = useState<StxAddressData>(undefined);
  const [stackingStatus, dispatchStackingStatus] = useReducer(reducer, initialState);
  const [initializedData, setInitializedData] = useState(false);

  const setUserStackingStatus = useCallback(
    ({
      userStackingData,
      stxTransactions,
      stxAddressData,
    }: {
      userStackingData: StackingData;
      stxTransactions: {
        confirmedTransactions: StxTransactionData[];
        pendingTransactions: StxMempoolTransactionData[];
      };
      stxAddressData: StxAddressData;
    }) => {
      const pendingRevokeOrDelegateTxList = getPendingRevokeOrDelegateTxList(
        stxTransactions.pendingTransactions,
      );

      const { hasPendingDelegate, hasPendingRevoke } = getPendingRevokeOrDelegateState(
        pendingRevokeOrDelegateTxList,
      );

      const stackingState = getStackingDisplayState({
        stackingData: userStackingData,
        hasPendingDelegate,
        hasPendingRevoke,
      });

      const pendingDelegateInfo = getPendingDelegateInfo(pendingRevokeOrDelegateTxList);
      const delegateInfo = getDelegateInfo({
        delegationInfo: userStackingData.delegationInfo,
        confirmedTxList: stxTransactions.confirmedTransactions,
      });

      const delegatedAmount = microstacksToStx(
        BigNumber(pendingDelegateInfo.amount || delegateInfo.amount),
      );
      const lockedAmount = microstacksToStx(
        BigNumber(userStackingData.stackerInfo.stacked ? stxAddressData.lockedStackBalance : 0),
      );

      const rewardAddress = userStackingData?.stackerInfo?.stacked
        ? userStackingData.stackerInfo.user_pox_address
        : delegateInfo.userPoxAddress || pendingDelegateInfo.userPoxAddress;
      const delegatedToAddress = delegateInfo.delegateTo || pendingDelegateInfo.delegateTo;

      dispatchStackingStatus({
        type: ActionType.SET_STACKING_STATUS,
        payload: {
          stackingState,
          delegatedStxData: {
            delegatedAmount,
            lockedAmount,
            poxBtcAddress: rewardAddress,
            delegatedToAddress,
            hasPendingDelegate,
          },
        },
      });
      setInitializedData(true);
    },
    [],
  );

  const fetchInfo = useCallback(async () => {
    let poolInfoResponse: StackingPoolInfo | undefined = stackingData?.poolInfo;
    let coreInfoResponse: CoreInfo | undefined = stackingData?.coreInfo;

    if (!poolInfoResponse) {
      poolInfoResponse = await fetchStackingPoolInfo();
    }
    if (!coreInfoResponse) {
      coreInfoResponse = await fetchCoreInfo();
    }
    if (!address) {
      setStackingData((prevStackingData) => ({
        ...prevStackingData,
        poolInfo: poolInfoResponse,
        coreInfo: coreInfoResponse,
      }));
      return;
    }

    const [stackerInfo, delegationInfo, stxTransactions, stxAddressData] = await Promise.all([
      fetchStackingStatus(address),
      fetchDelegationState(address),
      fetchStxTransactions(address),
      fetchStxAddressData(address),
    ]);

    const userStackingData: StackingData = {
      poolInfo: poolInfoResponse,
      coreInfo: coreInfoResponse,
      stackerInfo: stackerInfo,
      delegationInfo: delegationInfo,
    };
    setStackingData((prevStackingData) => ({
      ...prevStackingData,
      ...userStackingData,
    }));
    setStxAddressData(stxAddressData);
    setUserStackingStatus({
      userStackingData,
      stxTransactions,
      stxAddressData,
    });
  }, [address, setUserStackingStatus, stackingData?.coreInfo, stackingData?.poolInfo]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  return {
    stackingStatus,
    dispatchStackingStatus,
    initializedData,
    stackingData,
    stxAddressData,
    refetchStackingData: fetchInfo,
  };
}
