import React, { FC } from 'react';
import styled from 'styled-components';
import {
  color,
  gridColumn,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  GridColumnProps,
} from 'styled-system';

import { Theme } from '../../Theme';

type TextStyleProps = SpaceProps & GridColumnProps & TypographyProps & ColorProps & LayoutProps;

type TextProps = TextStyleProps &
  React.HTMLAttributes<HTMLParagraphElement> & {
    onClick?: () => void;
    children?: String | null | string | number | any;
  };

const TextStyle = styled.p<TextStyleProps>`
  margin: 0;
  word-wrap: break-word;
  ${space}
  ${typography}
  ${layout}
  ${color}
  ${gridColumn}
`;

export const HeadingText: FC<TextProps> = (props) => {
  return (
    <TextStyle
      fontFamily={'IBMPlexSans-Light'}
      fontWeight={300}
      fontSize={{ s: 32, md: 35, lg: 36 }}
      color={`${Theme.colors.white}`}
      textAlign={{ s: 'center', md: 'center', lg: 'start' }}
      {...props}
    >
      {props.children}
    </TextStyle>
  );
};

export const BodyText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_m} color={'white'} lineHeight={'160%'} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const ButtonText: FC<TextProps> = (props) => {
  return (
    <TextStyle fontSize={14} marginTop={2} style={Theme.body_bold_m} color={'white'} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const BoldInfoText: FC<TextProps> = (props) => {
  return (
    <TextStyle fontSize={14} fontFamily={'xlg'} color={'white'} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const FooterText: FC<TextProps> = (props) => {
  return (
    <TextStyle fontSize={13} style={Theme.body_medium_m} color={'lightGrey'} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const StxLockedTitleText: FC<TextProps> = (props) => {
  return (
    <TextStyle
      fontFamily="IBMPlexSans-Light"
      fontWeight={300}
      fontStyle="normal"
      letterSpacing={'0.02em'}
      color={`${Theme.colors.white}`}
      {...props}
    >
      {props.children}
    </TextStyle>
  );
};

export const LandingBodyText: FC<TextProps> = (props) => {
  return (
    <TextStyle
      fontFamily={'DMSans-Regular'}
      fontWeight={400}
      fontSize={18}
      color={'white'}
      lineHeight={'160%'}
      {...props}
    >
      {props.children}
    </TextStyle>
  );
};

export const StackingHeadingText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.headline_l} color={`white`} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const SubHeadingText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_medium_m} color={`white`} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const StackingTileText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.headline_category_s} color={'lightGrey'} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const StackingTileSubHeadingText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_m} color={'white'} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const StackingTileValueText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.tile_text} color={`white`} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const CurrencyText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_xs} color={`lightGrey`} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const StackingBodyText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_m} lineHeight={'140%'} color={`grey`} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const SmallHeadlineText: FC<TextProps> = (props) => {
  return (
    <TextStyle
      fontFamily={'Satoshi-Medium'}
      fontStyle={'normal'}
      fontWeight={700}
      fontSize={21}
      letterSpacing={0.02}
      color={'white'}
      {...props}
    >
      {props.children}
    </TextStyle>
  );
};

export const StackingProgressText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_medium_l} color={`white`} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const StatsCardText: FC<TextProps> = (props) => {
  return (
    <TextStyle
      fontFamily={'Satoshi-Regular'}
      fontStyle={'normal'}
      fontWeight={500}
      fontSize={16}
      color={`white`}
      {...props}
    >
      {props.children}
    </TextStyle>
  );
};

export const MediumBodyText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_m} color={`grey`} lineHeight={'140%'} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const BoldHeadingText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_bold_m} color={`white`} {...props}>
      {props.children}
    </TextStyle>
  );
};

export const PopUpText: FC<TextProps> = (props) => {
  return (
    <TextStyle style={Theme.body_bold_l} color={`white`} {...props}>
      {props.children}
    </TextStyle>
  );
};
