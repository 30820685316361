import { StackingState } from '../utils/constants';
import { Action, ActionType, StackingStatus } from '../utils/data.type';

function reducer(state: StackingStatus, action: Action): StackingStatus {
  switch (action.type) {
    case ActionType.SET_DELEGATING:
      const isFirsDelegation = state.stackingState === StackingState.NotStacking;
      return {
        stackingState: isFirsDelegation ? StackingState.Pending : state.stackingState,
        delegatedStxData: {
          ...action.payload.delegatedStxData,
          lockedAmount: isFirsDelegation
            ? action.payload.delegatedStxData.lockedAmount
            : state.delegatedStxData.lockedAmount,
        },
      };
    case ActionType.SET_NOT_STACKING:
      return { ...state, stackingState: StackingState.Revoked };
    case ActionType.SET_STACKING_STATUS:
      return { ...state, ...action.payload };
    case ActionType.RESET_STATE:
      return {
        stackingState: StackingState.NotStacking,
        delegatedStxData: null,
      };
    default:
      return state;
  }
}

export default reducer;
