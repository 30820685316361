import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import BarLoader from '../../../components/styled/BarLoader';
import { Theme } from '../../../Theme';
import { LoaderSize } from '../../../utils/constants';

const ColumnContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
});
const LoaderContainer = styled.div({
  marginTop: 6,
});

const Image = styled.img({
  marginRight: 6,
});

const RowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

const StatsCardText = styled.h1((props) => ({
  ...props.theme.body_medium_l,
  '@media (max-width: 900px)': {
    fontSize: 14,
  },
}));

const SmallHeadlineText = styled.h1((props) => ({
  ...props.theme.headline_s,
  '@media (max-width: 900px)': {
    fontSize: 16,
  },
}));

interface Props {
  title: string;
  value: string;
  image?: string;
  subValue?: string;
  displayNumberFormat?: boolean;
  showLoader?: boolean;
}
export const StatsCard = ({
  title,
  value,
  image,
  subValue,
  showLoader,
  displayNumberFormat,
}: Props) => {
  return (
    <ColumnContainer>
      <RowContainer>
        {image && <Image src={image} />}
        <StatsCardText>{title}</StatsCardText>
      </RowContainer>
      {showLoader ? (
        <LoaderContainer>
          <BarLoader
            loaderSize={LoaderSize.SMALLEST}
            color={Theme.colors.background}
            margin={'0'}
          />
        </LoaderContainer>
      ) : displayNumberFormat ? (
        <NumberFormat
          value={value}
          displayType={'text'}
          thousandSeparator={true}
          prefix=""
          renderText={(value: string) => <SmallHeadlineText>{`${value} STX`}</SmallHeadlineText>}
        />
      ) : (
        <SmallHeadlineText>{value}</SmallHeadlineText>
      )}
    </ColumnContainer>
  );
};
